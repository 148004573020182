import { AppDispatch } from "@redux/store";
// import {
//   listAdminError,
//   listAdminPending,
//   listAdminSuccess,
//   getListRoleSuccess,
// } from "@redux/slice/ListAdminSlice";
import API from "@configs/api";
import { getErr422 } from "@utilities/helper";
// import { createAdminError } from "@redux/slice/CreateAdminSlice";
import { FIRST_SIZE } from "@utilities/constant";

export const AdminListServices = {
  getListAdmin: (
    dispatch: AppDispatch,
    page: number,
    size: number,
    status: string,
    publishedDate: string
  ) => {
    // dispatch(listAdminPending());
    // API.getListAdmin("", page, size, status, publishedDate)
    //   .then((response: any) => {
    //     dispatch(listAdminSuccess(response.data.data));
    //   })
    //   .catch((err: any) => {
    //     dispatch(listAdminError(getErr422(err)));
    //   });
  },
  searchAdmin: (
    dispatch: AppDispatch,
    keySearch: string,
    page: number,
    size: number,
    status: string,
    publishedDate: string
  ) => {
    // dispatch(listAdminPending());
    // API.getListAdmin(keySearch, page, size, status, publishedDate)
    //   .then((response: any) => {
    //     dispatch(listAdminSuccess(response.data.data));
    //   })
    //   .catch((err: any) => {
    //     dispatch(listAdminError(getErr422(err)));
    //   });
  },
  changeStatusUser: (
    dispatch: AppDispatch,
    userId: number,
    data: any,
    page: number,
    status: string,
    publishedDate: string,
    onSuccess: any
  ) => {
    // dispatch(listAdminPending());
    // API.changeStatus(userId, data)
    //   .then((response) => {
    //     if (response?.data) {
    //       onSuccess();
    //     }
    //     API.getListAdmin("", page, FIRST_SIZE, status, publishedDate)
    //       .then((response: any) => {
    //         dispatch(listAdminSuccess(response.data.data));
    //       })
    //       .catch((err: any) => {
    //         dispatch(listAdminError(getErr422(err)));
    //       });
    //   })
    //   .catch((err: any) => {
    //     dispatch(listAdminError(getErr422(err)));
    //   });
  },
  changeRoleUser: (
    dispatch: AppDispatch,
    userId: number,
    data: any,
    page: number,
    status: string,
    publishedDate: string,
    onSuccess: any
  ) => {
    // dispatch(listAdminPending());
    // API.updateAdminUser(userId, data)
    //   .then((response) => {
    //     if (response?.data) {
    //       onSuccess();
    //     }
    //     API.getListAdmin("", page, FIRST_SIZE, status, publishedDate)
    //       .then((response: any) => {
    //         dispatch(listAdminSuccess(response.data.data));
    //       })
    //       .catch((err: any) => {
    //         dispatch(listAdminError(getErr422(err)));
    //       });
    //   })
    //   .catch((err: any) => {
    //     dispatch(listAdminError(getErr422(err)));
    //   });
  },
  getListRole: (dispatch: AppDispatch) => {
    // API.getListRoleSelect("", 1, 100)
    //   .then((response) => {
    //     dispatch(getListRoleSuccess(response.data.data.data));
    //   })
    //   .catch((err: any) => {
    //     dispatch(createAdminError(getErr422(err)));
    //   });
  },
};
