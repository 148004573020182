import { useDocumentTitle } from "@hooks/useDocumentTitle";
import { Row, Col, notification, DatePicker, Button } from "antd";
import "./index.scss";
import { useEffect, useState } from "react";
import API from "@configs/api";
import { DEFAULT_CURRENT_MONEY } from "@utilities/constant";
import LoadingComponent from "@components/LoadingComponent";

import { convertNumberToMonth, ternaryOperatorHandle } from "@utilities/helper";
import { AnyListenerPredicate } from "@reduxjs/toolkit";

const _ = require("lodash");
const { RangePicker } = DatePicker;
const Dashboard = () => {
  useDocumentTitle("Dashboard");
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalUserAuths, setTotalUserAuths] = useState(0);
  const [totalAppointments, setTotalAppointments] = useState(0);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);

  const handleDateChange = (dates: any) => {
    if (dates) {
      setStartDate(dates[0]);
      setEndDate(dates[1]);
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  };

  const handleSubmit = () => {
    // Định dạng ngày theo định dạng ISO 8601
    const formattedStartDate = startDate ? startDate.toISOString() : null;
    const formattedEndDate = endDate ? endDate.toISOString() : null;
    const params = {
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    };
    Promise.all([
      API.getDashboardServiceUser(params),
      API.getDashboardServiceAppointment(params),
    ])
      .then(
        ([
          totalDashboardServiceUserResponse,
          totalDashboardServiceAppointmentResponse,
        ]: any) => {
          console.log(
            "totalBalanceResponse: ",
            totalDashboardServiceUserResponse,
          );
          setTotalUsers(
            totalDashboardServiceUserResponse?.data?.data?.countRegister,
          );
          setTotalUserAuths(
            totalDashboardServiceUserResponse?.data?.data?.countAuth,
          );
          setTotalAppointments(
            totalDashboardServiceAppointmentResponse?.data?.data
              ?.countAppointment,
          );
        },
      )
      .catch((error: any) => {
        notification["error"]({
          message:
            error.response?.data?.message ||
            error.response?.data?.error?.message ||
            "An error occurred",
        });
      })
      .finally(() => setLoading(false)); // Đảm bảo loading được tắt cuối cùng
  };

  useEffect(() => {
    setLoading(true);
    Promise.all([
      API.getDashboardServiceUser({
        startDate,
        endDate,
      }),
      API.getDashboardServiceAppointment({
        startDate,
        endDate,
      }),
    ])
      .then(
        ([
          totalDashboardServiceUserResponse,
          totalDashboardServiceAppointmentResponse,
        ]: any) => {
          console.log(
            "totalBalanceResponse: ",
            totalDashboardServiceUserResponse,
          );
          setTotalUsers(
            totalDashboardServiceUserResponse?.data?.data?.countRegister,
          );
          setTotalUserAuths(
            totalDashboardServiceUserResponse?.data?.data?.countAuth,
          );
          setTotalAppointments(
            totalDashboardServiceAppointmentResponse?.data?.data
              ?.countAppointment,
          );
        },
      )
      .catch((error: any) => {
        notification["error"]({
          message:
            error.response?.data?.message ||
            error.response?.data?.error?.message ||
            "An error occurred",
        });
      })
      .finally(() => setLoading(false)); // Đảm bảo loading được tắt cuối cùng
  }, []);

  return (
    <div>
      <div
        className="list-user-cms-header"
        style={{ backgroundColor: "#FFFFFF", height: "120px", padding: "2%" }}
      >
        <h1 style={{ marginTop: "10px" }}>Thống kê EPHR</h1>
      </div>
      {loading ? (
        <div style={{ marginTop: "50px" }}>
          <LoadingComponent size="large" />
        </div>
      ) : (
        <>
          <div className="search-person" style={{ padding: "2%" }}>
            {/*Summarize total users and balance*/}
            <Row gutter={[6, 6]}>
              <Col span={6}>
                <div
                  style={{
                    padding: "8%",
                    borderRadius: "10px",
                    backgroundColor: "#e5ecf6",
                  }}
                >
                  <div className="dashboard-firstline-item-title">
                    Tổng số người dùng đăng ký
                  </div>
                  <div className="dashboard_firstline_item_value">
                    {totalUsers ?? 0}
                  </div>
                </div>
              </Col>
              <Col span={6}>
                <div
                  style={{
                    padding: "8%",
                    borderRadius: "10px",
                    backgroundColor: "#e3f5ff",
                  }}
                >
                  <div className="dashboard-firstline-item-title">
                    Tổng số người dùng đã xác thực
                  </div>
                  <div className="dashboard_firstline_item_value">
                    {totalUserAuths.toLocaleString() ?? 0}{" "}
                  </div>
                </div>
              </Col>
              <Col span={6}>
                <div
                  style={{
                    padding: "8%",
                    borderRadius: "10px",
                    backgroundColor: "#e3f5ff",
                  }}
                >
                  <div className="dashboard-firstline-item-title">
                    Tổng số đặt hẹn
                  </div>
                  <div className="dashboard_firstline_item_value">
                    {totalAppointments.toLocaleString() ?? 0}{" "}
                  </div>
                </div>
              </Col>
              <Col span={6}>
                <div
                  style={{
                    padding: "8%",
                    borderRadius: "10px",
                    backgroundColor: "#e3f5ff",
                  }}
                >
                  <div className="flex gap-2">
                    <div className="dashboard-firstline-item-title">
                      <RangePicker
                        format="YYYY-MM-DD"
                        value={[startDate, endDate]}
                        onChange={handleDateChange}
                        allowClear
                      />
                    </div>
                    <div className="dashboard_firstline_item_value">
                      <Button type="primary" onClick={handleSubmit}>
                        Tìm kiếm
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <br />
          </div>
        </>
      )}
    </div>
  );
};

export default Dashboard;

