import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Breadcrumb } from "antd";
import STORAGES_CONFIG from "@configs/storage";
import { ROUTE_PATH } from "@ts/enums";

const BreadcrumbCustom = (props: any) => {
  const [listPath, setListPath] = useState<any>([]);
  const navigate = useNavigate();

  useEffect(() => {
    setListPath(props.path);
  }, [props.path]);

  return (
    <Breadcrumb>
      {listPath.map((item: any) => {
        return (
          <Breadcrumb.Item key={item.key}>
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (item.path)
                  if (
                    item.path === ROUTE_PATH.dashboard ||
                    item.path === ROUTE_PATH.home
                  ) {
                    localStorage.setItem(
                      STORAGES_CONFIG.selectedKeys,
                      JSON.stringify([ROUTE_PATH.dashboard]),
                    );
                    navigate(item.path);
                  } else {
                    navigate(item.path);
                  }
              }}
            >
              {item.label}
            </span>
          </Breadcrumb.Item>
        );
      })}
    </Breadcrumb>
  );
};

export default BreadcrumbCustom;
