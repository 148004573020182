import { createSlice } from "@reduxjs/toolkit";

export interface UpdateAdminState {
  loading: boolean;
  image: string;
  error: any;
  refresh: boolean;
}

const initialState: UpdateAdminState = {
  loading: false,
  image: "",
  error: {},
  refresh: false,
};

export const Slice = createSlice({
  name: "updateAdmin",
  initialState,
  reducers: {
    clearStateImage: (state) => {
      state.image = "";
    },
    updateAdminPending: (state) => {
      state.loading = true;
    },
    updateAdminSuccess: (state) => {
      state.loading = false;
      state.error = {};
      state.refresh = !state.refresh;
    },
    updateAvatarSuccess: (state, action) => {
      state.image = action.payload.data.image;
      state.loading = false;
      state.error = {};
    },
    updateAdminError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateAdminPending,
  updateAdminSuccess,
  updateAdminError,
  updateAvatarSuccess,
  clearStateImage,
} = Slice.actions;

export default Slice.reducer;
