import "@shopify/polaris/build/esm/styles.css";
import { useDocument } from "./hooks/useDocument";
import HttpHandler from "@components/HttpHandler";
// import "antd/dist/reset.css";
import "@assets/scss/App.scss";
import "bootstrap/dist/css/bootstrap.css";
// import "font-awesome/css/font-awesome.min.css";
import Routes from "@routes/Routers";
import enTranslations from "@shopify/polaris/locales/en.json";
import { AppProvider } from "@shopify/polaris";
import { Suspense } from "react";
import CustomSkeletonPage from "@components/Skeleton/skeleton-page";

function App() {
  useDocument("Ephr");
  return (
    <AppProvider i18n={enTranslations}>
      <HttpHandler />
      <Suspense fallback={<CustomSkeletonPage />}>
        <Routes />
      </Suspense>
    </AppProvider>
  );
}

export default App;

