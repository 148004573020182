export enum ENDPOINT_API {
  login = "/user/auths/dang-nhap",
  getDetailUser = "/user/get-profile",
  signout = "/user/signout",
  forgotPassword = "/user/forgot-password",
  changePassword = "/user/change-password",

  // dashboard
  dashboardSerivceUser = "/user/thong-ke",
  dashboardSerivceAppointment = "/appointment/thong-ke",

  // check service
  checkHealthService = "/worker/list-service-check",
}

export enum ROUTE_PATH {
  home = "/cms/dashboard",
  login = "/cms/login",
  dashboard = "/cms/dashboard",
  listUsers = "/cms/cms-users-list",
  listUsersLandingPage = "/cms/landing-page-users-list",

  changePassword = "/cms/change-password",
  logout = "/cms/logout",
  profile = "/cms/profile",
  userId = "/cms/user/:userId",

  // health
  healthCompany = "/cms/health/company",
  healthHospital = "/cms/health/hospital",
  health = "health",
}

export enum HTTP_STATUS_CODE {
  OK = 200,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  PAYMENT_REQUIRED = 402,
  NOT_FOUND = 404,
  PROXY_AUTHENTICATION_REQUIRED = 407,
  CONFLICT = 409,
  INTERNAL_SERVER_ERROR = 500,

  EPHR_OK_LOGIN = 0,
}

export enum IMAGE_TYPES {
  jpeg = "image/jpeg",
  png = "image/png",
  jpg = "image/jpg",
}

export const RESOURCE_DEFAULT = {
  USER_MANAGEMENT: {
    name: "User management",
    key: "user_management",
  },
  ROLE_MANAGEMENT: {
    name: "Role management",
    key: "role_management",
  },
  USER_CMS_MANAGEMENT: {
    name: "CMS user management",
    key: "cms_user_management",
  },
  USER_LP_MANAGEMENT: {
    name: "Landing page user management",
    key: "lp_user_management",
  },
  CMS_ROLE: {
    name: "CMS role management",
    key: "cms_role_management",
  },
  LP_ROLE: {
    name: "Landing page role management",
    key: "lp_role_management",
  },
  COMPANY: {
    name: "Company",
    key: "company",
  },
  INDUSTRY: {
    name: "Industry",
    key: "industry",
  },
  COUNTRY: {
    name: "Country",
    key: "country",
  },
  REGION: {
    name: "Region",
    key: "region",
  },
  PERSON: {
    name: "Person",
    key: "person",
  },
  ACQUISITION: {
    name: "Acquisition",
    key: "acquisition",
  },
  NEWS: {
    name: "News",
    key: "news",
  },
  INVESTOR: {
    name: "Investor",
    key: "investor",
  },
  FUNDING: {
    name: "Funding",
    key: "funding",
  },
  ONEPAGER: {
    name: "One Pager",
    key: "onepager",
  },
  ANALYSIS: {
    name: "Analysis & Survey",
    key: "analysisSurvey",
  },
  INSIGHT: {
    name: "Insight",
    key: "insight",
  },
  VIDEO: {
    name: "Video Distribution",
    key: "videoDistribution",
  },
  PAYMENT: {
    name: "Payment",
    key: "payment",
  },
};
export const PERMISSION_DEFAULT = {
  CREATE: {
    name: "create",
    key: "create",
  },
  UPDATE: {
    name: "update",
    key: "update",
  },
  DELETE: {
    name: "delete",
    key: "delete",
  },
  VIEW: {
    name: "view",
    key: "view",
  },
  PUBLISH: {
    name: "publish",
    key: "publish",
  },
};

export enum AREA_TYPE {
  HEAD_QUARTER = "HEAD_QUARTER",
}

export enum SNS_TYPE {
  FACEBOOK = "Facebook",
  TWITTER = "Twitter",
  LINKEDIN = "LinkedIn",
}

export enum INVESTOR_FORM_ITEM_NAME {
  facebookUrl = "facebookUrl",
  instagramUrl = "instagramUrl",
  twitterUrl = "twitterUrl",
  linkedinUrl = "linkedinUrl",
  website = "website",
  email = "email",
  overview = "overview",
  foundedDate = "foundedDate",
  companyName = "companyName",
  articleUrl = "articleUrl",
  articleTitle = "articleTitle",
  numberOfEmployee = "numberOfEmployee",
  investorType = "investorType",
  investmentStage = "investmentStage",
  acceleratorApplicationDeadline = "acceleratorApplicationDeadline",
  acceleratorDuration = "acceleratorDuration",
  acceleratorProgramType = "acceleratorProgramType",
  phoneNumber = "phoneNumber",
  headquarters = "headquarters",
  industries = "industries",
  founders = "founders",
  teamMembers = "teamMembers",
  fundingRounds = "fundingRounds",
  acquisitions = "acquisitions",
  fundRaised = "fundRaised",
  news = "news",
  mainInvestmentField = "mainInvestmentField",
  operatingStatus = "operatingStatus",
  mission = "mission",
  vision = "vision",
  registrationNumber = "registrationNumber",
  isPublished = "isPublished",
  numberOfInvestment = "numberOfInvestment",
  numberOfExit = "numberOfExit",
  numberOfPortfolioOrganization = "numberOfPortfolioOrganization",
  numberOfDiversityInvestment = "numberOfDiversityInvestment",
  numberOfEvent = "numberOfEvent",
  numberOfLeadInvestment = "numberOfLeadInvestment",
  cbRankCompany = "cbRankCompany",
  ipoStatus = "ipoStatus",
}

export enum PERSON_POSITION {
  FOUNDER = "FOUNDER",
  TEAM_MEMBER = "TEAM_MEMBER",
}

export enum VIDEO_TYPES {
  mov = "video/mov",
  mp4 = "video/mp4",
}

export enum SERVICE_TYPE {
  INTERNAL = "INTERNAL",
  EXTERNAL = "EXTERNAL",
}

export enum StatusHeaalthCheck {
  ACTIVE = "ACTIVE",
  INACTIVE = "ACTIVE",
  CHECKING = "CHECKING",
}
