import { useEffect, useState } from "react";
import {
  Button,
  Col,
  DatePicker,
  Empty,
  Row,
  Select,
  Space,
  Switch,
  Table,
  Tooltip,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  USER_CMS_FILTER_STATUS,
  MAX_LENGTH_USER_FULLNAME,
  NOT_HAVE_PERMISSION,
  ACTIVE_LABEL,
  INACTIVE_LABEL,
  FIRST_PAGE,
  FIRST_SIZE,
} from "@utilities/constant";
import { RootState } from "@redux/store";
import { useDocumentTitle } from "@hooks/useDocumentTitle";
// import { ListUserDataType } from "@ts/interfaces";
import { AdminListServices } from "@pages/Users/ListUsers/Service";
import PaginationCustom from "@components/PaginationCustom";
import { PERMISSION_DEFAULT, RESOURCE_DEFAULT, ROUTE_PATH } from "@ts/enums";
import { useBeforeRender } from "@hooks/useBeforeRender";
import NotificationCustom from "@components/NotificationCustom";
import { PlusOutlined } from "@ant-design/icons";
import BreadcrumbCustom from "@components/BreadcrumbCustom";
import SearchComponent from "@components/SearchComponent";

const ListUsers = () => {
  useDocumentTitle("User CMS List");

  const emailAdministrator = "doublefeather@gmail.com";
  const auth = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const listAdmin = null; //useSelector((state: RootState) => state.listAdmin);
  const [listRole, setListRole] = useState<any[]>([]);
  const [hasPermissionCreate, setHasPermissionCreate] = useState(false);
  const [hasPermissionUpdate, setHasPermissionUpdate] = useState(false);
  const [page, setPage] = useState(FIRST_PAGE);
  const [size, setSize] = useState(FIRST_SIZE);
  const [statusPublish, setStatusPublish] = useState("");
  const [publishedDate, setPublishedDate] = useState("");
  const [notify, setNotify] = useState({
    isOpen: false,
    type: "",
    content: "",
  });
  const loading = false; //listAdmin.loading;
  const refresh = false;
  const onSuccess = () => {
    setNotify({
      isOpen: true,
      type: "success",
      content: "You have update successfully!",
    });
    hideNotify();
  };

  const hideNotify = () => {
    setTimeout(() => {
      setNotify({
        isOpen: false,
        type: "",
        content: "",
      });
    }, 2000);
  };

  const onSearch = (value: string) => {
    value = value.trim();
    setPage(1);
    AdminListServices.searchAdmin(
      dispatch,
      value,
      page,
      size,
      statusPublish,
      publishedDate
    );
  };

  useBeforeRender(() => {
    AdminListServices.getListRole(dispatch);
    const currentResource = auth?.roles?.resources.filter(
      (item: any) =>
        RESOURCE_DEFAULT.USER_CMS_MANAGEMENT.key === item.resourceKey
    );
    if (currentResource?.length) {
      const persmisionForUpdateUser = currentResource[0].permissions.filter(
        (item: any) => PERMISSION_DEFAULT.UPDATE.key === item.permissionKey
      );
      if (persmisionForUpdateUser.length) {
        setHasPermissionUpdate(true);
      }
      const persmisionForCreateUser = currentResource[0].permissions.filter(
        (item: any) => PERMISSION_DEFAULT.CREATE.key === item.permissionKey
      );
      if (persmisionForCreateUser.length) {
        setHasPermissionCreate(true);
      }
    }
  }, []);

  // useEffect(() => {
  //   const listRoles = listAdmin?.listRoles?.map((record: any) => {
  //     return {
  //       value: record.name,
  //       ...record,
  //     };
  //   });
  //   setListRole(listRoles);
  // }, [listAdmin?.listRoles]);

  const onChangeStatus = (rowData: any) => {
    let status;
    if (rowData.status === 1) {
      status = 2;
    } else {
      status = 1;
    }
    const data = {
      status: status,
    };
    AdminListServices.changeStatusUser(
      dispatch,
      rowData.id,
      data,
      page,
      statusPublish,
      publishedDate,
      onSuccess
    );
  };

  const handleTableChange = (page: any, size: any) => {
    setPage(page);
    setSize(size);
  };

  const handleChangeRole = (userId: number, fullName: string, role: any) => {
    const roleSelect = listRole.filter((record) => record.value === role);
    if (roleSelect.length > 0) {
      AdminListServices.changeRoleUser(
        dispatch,
        userId,
        { roleId: roleSelect[0].id, fullName: fullName },
        page,
        statusPublish,
        publishedDate,
        onSuccess
      );
    }
  };

  const columns: ColumnsType<any> = [
    {
      title: "Admin Name",
      dataIndex: "fullName",
      key: "fullName",
      render: (_, row) => (
        <>
          {row.fullName ? (
            <span>
              {row.fullName?.length >= MAX_LENGTH_USER_FULLNAME
                ? row.fullName.slice(0, MAX_LENGTH_USER_FULLNAME) + "..."
                : row.fullName}
            </span>
          ) : (
            <span>-</span>
          )}
        </>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, row) => (
        <>
          {row.status === 1 ? (
            <Tooltip
              placement="right"
              title={!hasPermissionUpdate ? NOT_HAVE_PERMISSION : ""}
            >
              <Switch
                defaultChecked
                onChange={() => {
                  onChangeStatus(row);
                }}
                disabled={
                  (row.email === emailAdministrator ? true : loading) ||
                  !hasPermissionUpdate
                }
              />
            </Tooltip>
          ) : (
            <Tooltip
              placement="right"
              title={!hasPermissionUpdate ? NOT_HAVE_PERMISSION : ""}
            >
              <Switch
                onChange={() => {
                  onChangeStatus(row);
                }}
                disabled={
                  (row.email === emailAdministrator ? true : loading) ||
                  !hasPermissionUpdate
                }
              />
            </Tooltip>
          )}
        </>
      ),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (_, row) => (
        <>
          {row.createdAt ? (
            <div>
              <div>
                {moment(row.createdAt).format("MM/DD/YYYY")},{" "}
                {moment(row.createdAt).format("HH:mm:ss A")}
              </div>
            </div>
          ) : (
            <div>-</div>
          )}
        </>
      ),
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
      render: (_, row) => (
        <>{row.createdBy ? <div>{row.createdBy}</div> : <div>-</div>}</>
      ),
    },
    {
      title: "Role",
      key: "roles",
      dataIndex: "roles",
      render: (tags, record: any) => {
        return (
          <Tooltip
            placement="right"
            title={!hasPermissionUpdate ? NOT_HAVE_PERMISSION : ""}
          >
            <Select
              value={`${tags[0].roleName}`}
              style={{ width: 120 }}
              loading={loading}
              onChange={(e: any) =>
                handleChangeRole(record.id, record.fullName, e)
              }
              disabled={
                !hasPermissionUpdate ||
                record.email === emailAdministrator ||
                record.status === 2
              }
              options={listRole}
            />
          </Tooltip>
        );
      },
    },
  ];

  useEffect(() => {
    AdminListServices.getListAdmin(
      dispatch,
      page,
      size,
      statusPublish,
      publishedDate
    );
  }, [refresh, page, size, statusPublish, publishedDate]);

  const items: any = [
    { label: "Home", key: "Home", path: "/" },
    { label: "CMS Users Management", key: "CMS Users Management", path: null },
  ];

  const itemsFilterStatus: any = [
    {
      value: USER_CMS_FILTER_STATUS.default,
      label: "All Status",
    },
    {
      value: USER_CMS_FILTER_STATUS.active,
      label: ACTIVE_LABEL,
    },
    {
      value: USER_CMS_FILTER_STATUS.inactive,
      label: INACTIVE_LABEL,
    },
  ];

  const handleChangeStatus = (key: any) => {
    setStatusPublish(key);
  };

  return (
    <>
      <div
        className="list-user-cms-header"
        style={{
          backgroundColor: "#FFFFFF",
          height: "150px",
          padding: "2%",
        }}
      >
        <BreadcrumbCustom path={items} />
        <h1 style={{ marginTop: "15px" }}>CMS Users</h1>
        {notify?.isOpen && (
          <NotificationCustom type={notify.type} content={notify.content} />
        )}
      </div>
      <div className="search-person" style={{ padding: "2%" }}>
        <Row gutter={[16, 16]} align="middle">
          <Col span={12}>
            <Space size={[2, 16]} style={{ width: "100%" }}>
              <div style={{ fontSize: "14px", width: "90px" }}>Admin Name:</div>
              <div style={{ width: "100%" }}>
                <SearchComponent
                  placeholder="Fill name or email to search"
                  allowClear
                  onSearch={onSearch}
                  enterButton
                  style={{ width: 500 }}
                />
              </div>
            </Space>
          </Col>
          <Col span={12}>
            <Space size={[4, 16]} style={{ width: "100%" }}>
              <div style={{ fontSize: "14px", width: "75px" }}>Created at:</div>
              <div style={{ width: "100%" }}>
                <DatePicker
                  placeholder="Select Date"
                  style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    width: "100%",
                    borderRadius: "2px",
                  }}
                  onChange={(e: any) => {
                    setPublishedDate(
                      e ? moment(e).startOf("day").toISOString() : ""
                    );
                  }}
                />
              </div>
            </Space>
          </Col>
        </Row>
        <Space split wrap>
          <Select
            defaultValue={itemsFilterStatus[0]}
            style={{ width: 120 }}
            onChange={handleChangeStatus}
            options={itemsFilterStatus}
            value={statusPublish}
          />
          <Tooltip
            placement="right"
            title={!hasPermissionCreate ? NOT_HAVE_PERMISSION : ""}
          >
            <Button
              type="primary"
              disabled={!hasPermissionCreate}
              onClick={() => {
                // history.push(ROUTE_PATH.createNewUser);
              }}
              style={{ marginRight: 8 }}
              icon={<PlusOutlined />}
            >
              Add new
            </Button>
          </Tooltip>
        </Space>
      </div>
      <div className="container-list-person" style={{ padding: "2%" }}>
        <Table
          columns={columns}
          // dataSource={listAdmin.data}
          rowKey="id"
          pagination={false}
          scroll={{ x: 1200 }}
          loading={loading}
          locale={{
            emptyText: loading ? (
              <div style={{ height: "100px", background: "#fff" }}></div>
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            ),
          }}
        />
        {!loading && (
          <PaginationCustom
            // total={listAdmin.total}
            handleTableChange={handleTableChange}
            current={page}
            size={size}
          />
        )}
      </div>
    </>
  );
};

export default ListUsers;
