import { AppDispatch } from "@redux/store";
import {
  changePassError,
  changePassPending,
  changePassSuccess,
} from "@redux/slice/ChangePasswordSlice";
import API from "@configs/api";
import { getErr422 } from "@utilities/helper";

export const ChangePasswordServices = {
  run: (dispatch: AppDispatch, params: any, onSuccess: () => void) => {
    dispatch(changePassPending());
    API.changePassword(params)
      .then(() => {
        dispatch(changePassSuccess());
        onSuccess();
      })
      .catch((err: any) => {
        dispatch(changePassError(getErr422(err)));
      });
  },
};
