import { INFINITY_PAGE_SIZE } from "@utilities/constant";
import { http } from "@utilities/http";
import { httpAuth } from "@utilities/httpAuth";
import { ENDPOINT_API, SERVICE_TYPE } from "@ts/enums";
import STORAGES_CONFIG from "@configs/storage";

export const BearerToken = () => {
  return `Bearer ${localStorage.getItem(STORAGES_CONFIG.token)}`;
};

const API = {
  login: (data: any) => http.post(ENDPOINT_API.login, data),
  logout: () =>
    http.post(ENDPOINT_API.signout, "", {
      headers: { Authorization: BearerToken() },
    }),
  forgotPassword: (data: any) => http.post(ENDPOINT_API.forgotPassword, data),
  changePassword: (data: any) =>
    http.put(ENDPOINT_API.changePassword, data, {
      headers: { Authorization: BearerToken() },
    }),

  getDetailUser: () => httpAuth.get(ENDPOINT_API.getDetailUser),

  getDashboardServiceUser: (data: any) =>
    httpAuth.post(ENDPOINT_API.dashboardSerivceUser, data),
  getDashboardServiceAppointment: (data: any) =>
    httpAuth.post(ENDPOINT_API.dashboardSerivceAppointment, data),

  getListServiceCheck: (
    search: string,
    serviceType: SERVICE_TYPE,
    page: number,
    size: number,
  ) =>
    httpAuth.get(ENDPOINT_API.checkHealthService, {
      params: {
        search: search ? search : null,
        page,
        size,
        serviceType,
      },
    }),
  // getAllHospital
  // getListAdmin: (
  //   keySearch: string,
  //   page: number,
  //   size: number,
  //   status: string,
  //   publishedDate: string,
  // ) =>
  //   httpAuth.get(ENDPOINT_API.listAdmin, {
  //     params: {
  //       keySearch,
  //       page,
  //       size,
  //       status,
  //       publishedDate,
  //     },
  //   }),
  // getListUsersLandingPage: (
  //   keySearch: string,
  //   page: number,
  //   size: number,
  //   status: string,
  //   publishedDate: string,
  // ) =>
  //   httpAuth.get(ENDPOINT_API.listUserLandingPage, {
  //     params: {
  //       keySearch,
  //       page,
  //       size,
  //       status,
  //       publishedDate,
  //     },
  //   }),
  // deleteAdmin: (userId: number) =>
  //   httpAuth.delete(`/user/delete-admin/${userId}`),
  // updateAdminUser: (userId: number, data: any) =>
  //   httpAuth.put(`/user/edit-admin/${userId}`, data),
  // changeStatus: (userId: number, data: any) =>
  //   httpAuth.put(`/user/change-status/${userId}`, data),
  // createAdmin: (data: any) => httpAuth.post(ENDPOINT_API.createAdmin, data),
  // uploadAvatar: (formData: any) => httpAuth.post(ENDPOINT_API.upFile, formData),
  // getSingleAdmin: (userId: number) =>
  //   httpAuth.get(`/user/detail-admin/${userId}`),

  // updateAdmin: (data: any) => httpAuth.put(ENDPOINT_API.updateProfile, data),
  // createRole: (data: any) => httpAuth.post(ENDPOINT_API.roleCreate, data),
  // getListRole: (keySearch: string, page: number, size: number) =>
  //   httpAuth.get(ENDPOINT_API.roleList, {
  //     params: {
  //       keySearch,
  //       page,
  //       size,
  //     },
  //   }),
  // getListRoleSelect: (keySearch: string, page: number, size: number) =>
  //   httpAuth.get(ENDPOINT_API.roleListSelect, {
  //     params: {
  //       keySearch,
  //       page,
  //       size,
  //     },
  //   }),
  // getListResource: (page: number, size: number) =>
  //   httpAuth.get(ENDPOINT_API.resourceList, {
  //     params: {
  //       page,
  //       size,
  //     },
  //   }),
  // getDetailRole: (userId: number) => httpAuth.get(`/role/detail/${userId}`),
  // updateRole: (roleId: number, data: any) =>
  //   httpAuth.put(`/role/edit/${roleId}`, data),
  // deleteRole: (roleId: number | undefined) =>
  //   httpAuth.delete(`/role/delete/${roleId}`),
  // createNewCompany: (data: any) =>
  //   httpAuth.post(ENDPOINT_API.createNewCompany, data),
  // importCompany: (data: any) => httpAuth.post(ENDPOINT_API.companyImport, data),
  // importPerson: (data: any) => httpAuth.post(ENDPOINT_API.personImport, data),
  // editCompany: ({ companyId, data }: any) =>
  //   httpAuth.put(`/company/edit_company/${companyId}`, data),
  // getAllCompany: (
  //   publishedDate: string,
  //   searchValue: string,
  //   page: number,
  //   size: number,
  //   createdBy: string,
  // ) =>
  //   httpAuth.get(ENDPOINT_API.companyListAll, {
  //     params: {
  //       publishedDate,
  //       searchValue,
  //       page,
  //       size,
  //       createdBy,
  //     },
  //   }),
  // listAllCompanyName: (page: number, size: number, keySearch = "") =>
  //   httpAuth.get(ENDPOINT_API.companyListName, {
  //     params: {
  //       page,
  //       size,
  //       keySearch,
  //     },
  //   }),
  // getCompanyById: (companyId: string) =>
  //   httpAuth.get(`/company/get_company/${companyId}`),
  // deleteCompany: (companyId: string) =>
  //   http.delete(ENDPOINT_API.companyDeleteCompany + companyId, {
  //     params: {
  //       companyId,
  //     },
  //     headers: {
  //       Authorization: BearerToken(),
  //     },
  //   }),
  // deleteMultipleCompany: (data: any[]) =>
  //   httpAuth.post(ENDPOINT_API.companyDeleteMany, {
  //     listCompanyId: data,
  //   }),
  // uploadImage: (formData: any, companyId: string, typeUpload: string) =>
  //   httpAuth.post(`/company/upload-image/${companyId}`, formData, {
  //     params: {
  //       typeUpload,
  //     },
  //   }),
  // downLoadTemplateCompany: () => httpAuth.get(ENDPOINT_API.companyTemplate),
  // getAreaCompanyListAll: (
  //   page: number,
  //   size: number,
  //   freeName: string,
  //   publishedDate: string,
  // ) =>
  //   httpAuth.get(ENDPOINT_API.areasListAll, {
  //     params: {
  //       page,
  //       size,
  //       freeName,
  //       publishedDate,
  //     },
  //   }),
  // getAreaCompanyListAllTree: (
  //   page: number,
  //   size: number,
  //   typeSort: string,
  //   keySearch: string,
  //   isMostUsed = 0,
  // ) =>
  //   httpAuth.get(ENDPOINT_API.areasListAllTree, {
  //     params: {
  //       page: page,
  //       size: size,
  //       typeSort,
  //       keySearch,
  //       isMostUsed,
  //     },
  //   }),
  // getIndustryGetAllTree: () => httpAuth.get(ENDPOINT_API.industriesListAllTree),
  // getAllPerson: () =>
  //   httpAuth.get(ENDPOINT_API.personListName, {
  //     params: {
  //       size: INFINITY_PAGE_SIZE,
  //     },
  //   }),
  // litsAllInvestorName: () =>
  //   httpAuth.get(ENDPOINT_API.investorListName, {
  //     params: {
  //       size: INFINITY_PAGE_SIZE,
  //     },
  //   }),
  // getAreaListAllTree: (
  //   page: number,
  //   size: number,
  //   typeSort = "",
  //   keySearch = "",
  //   isMostUsed = 0,
  // ) =>
  //   httpAuth.get(ENDPOINT_API.areasListAllTree, {
  //     params: {
  //       page: page,
  //       size: size,
  //       typeSort,
  //       keySearch,
  //       isMostUsed,
  //     },
  //   }),
  // getSingleArea: (areaId: string) => httpAuth.get(`/areas/get_area/${areaId}`),
  // createArea: (data: any) =>
  //   httpAuth.post(ENDPOINT_API.areasCreateNewArea, data),
  // changeArea: (areaId: string, data: any) =>
  //   httpAuth.put(ENDPOINT_API.areasEditArea, data, {
  //     params: {
  //       areaId,
  //     },
  //   }),
  // deleteArea: (areaId: any) =>
  //   httpAuth.delete(ENDPOINT_API.areasDeleteArea, {
  //     params: {
  //       areaId,
  //     },
  //   }),
  // deleteManyAreas: (body: any) =>
  //   httpAuth.post(ENDPOINT_API.areasDeleteManyArea, {
  //     ...body,
  //   }),
  // getAllIndustries: (
  //   page: number,
  //   size: number,
  //   freeName: string,
  //   publishedDate: string,
  // ) =>
  //   httpAuth.get(ENDPOINT_API.industriesListAll, {
  //     params: {
  //       page,
  //       size,
  //       freeName,
  //       publishedDate,
  //     },
  //   }),
  // getIndustryListAllTree: (
  //   page: number,
  //   size: number,
  //   typeSort = "",
  //   keySearch = "",
  //   isMostUsed = 0,
  // ) =>
  //   httpAuth.get(ENDPOINT_API.industriesListAllTree, {
  //     params: {
  //       page: page,
  //       size: size,
  //       typeSort,
  //       keySearch,
  //       isMostUsed,
  //     },
  //   }),
  // getSingleIndustry: (industryId: string) =>
  //   httpAuth.get(`/industries/get_industry/${industryId}`),
  // createIndustry: (data: any) =>
  //   httpAuth.post(ENDPOINT_API.industriesCreateNewIndustry, data),
  // changeIndustry: (industryId: string, data: any) =>
  //   httpAuth.put(ENDPOINT_API.industriesEditIndustry, data, {
  //     params: {
  //       industryId,
  //     },
  //   }),
  // deleteIndustry: (industryId: string) =>
  //   httpAuth.delete(ENDPOINT_API.industriesDeleteIndustry, {
  //     params: {
  //       industryId,
  //     },
  //   }),
  // deleteManyIndustries: (body: any) =>
  //   httpAuth.post(ENDPOINT_API.industriesDeleteManyIndustry, {
  //     ...body,
  //   }),
  // getAllPersons: (
  //   page: number,
  //   size: number,
  //   keySearch: string,
  //   publishedDate: string,
  //   statusPublish: any,
  // ) =>
  //   httpAuth.get(ENDPOINT_API.personListAll, {
  //     params: {
  //       page,
  //       size,
  //       keySearch,
  //       publishedDate,
  //       isPublished: statusPublish,
  //     },
  //   }),
  // getAllListNamePersons: () => httpAuth.get(ENDPOINT_API.personListName),
  // getPersonById: (personId: string) =>
  //   httpAuth.get(`/person/get_person/${personId}`),
  // createPerson: (data: any) =>
  //   httpAuth.post(ENDPOINT_API.personCreateNewPerson, data),
  // changePerson: (personId: string, data: any) =>
  //   httpAuth.put(`/person/edit_person/${personId}`, data),
  // deletePerson: (personId: string) =>
  //   httpAuth.delete(`/person/delete_person/${personId}`),
  // deleteManyPerson: (body: any) =>
  //   httpAuth.post(ENDPOINT_API.personDeleteMany, {
  //     ...body,
  //   }),
  // uploadAvatarPerson: (personId: string, file: any) =>
  //   httpAuth.post(`/person/upload-file/${personId}`, file),
  // downLoadFilePerson: () => httpAuth.get(ENDPOINT_API.personTemplate),
  // getAllAcquisition: (
  //   publishedDate: string,
  //   keySearch: string,
  //   page: number,
  //   size: number,
  //   acquisitionStatus: string,
  // ) =>
  //   httpAuth.get(ENDPOINT_API.acquisitionGetAll, {
  //     params: {
  //       page,
  //       size,
  //       keySearch,
  //       publishedDate,
  //       acquisitionStatus,
  //     },
  //   }),
  // importAcquisition: (data: any) =>
  //   httpAuth.post(ENDPOINT_API.acquisitionImport, data),
  // deleteAcquisition: (acquisitionId: string) =>
  //   httpAuth.delete(`/acquisition/delete/${acquisitionId}`, {}),
  // deleteMultipleAcquisition: (data: any[]) =>
  //   httpAuth.post(ENDPOINT_API.acquisitionDeleteMany, {
  //     listAcquisitionId: data,
  //   }),
  // createAcquisition: (data: any) =>
  //   httpAuth.post(ENDPOINT_API.acquisitionCreateNew, data),
  // downLoadTemplateAcquisition: () =>
  //   httpAuth.get(ENDPOINT_API.acquisitionTemplate),
  // getAcquisitionById: (acquisitionId: string) =>
  //   httpAuth.get(`/acquisition/${acquisitionId}`),
  // editAcquisition: (acquisitionId: string, data: any) =>
  //   httpAuth.put(`/acquisition/update/${acquisitionId}`, data),
  // getAllNews: (
  //   publishedDate: string,
  //   keySearch: string,
  //   page: number,
  //   size: number,
  // ) =>
  //   httpAuth.get(ENDPOINT_API.articleListAll, {
  //     params: {
  //       publishedDate,
  //       keySearch,
  //       page,
  //       size,
  //     },
  //   }),
  // changePublished: (companyId: any, isPublished: any) =>
  //   httpAuth.post(`/company/change-publish/${companyId}`, {
  //     isPublished,
  //   }),
  // createNews: (data: any) => httpAuth.post(ENDPOINT_API.articleCreateNew, data),
  // uploadImageNews: (newsId: string, file: any) =>
  //   httpAuth.post(`/article/upload-file/${newsId}`, file),
  // deleteNews: (newsId: string) => httpAuth.delete(`/article/delete/${newsId}`),
  // deleteManyNews: (data: any[]) =>
  //   httpAuth.post(ENDPOINT_API.articleDeleteMany, {
  //     listNewsId: data,
  //   }),
  // getNewsById: (newsId: string) => httpAuth.get(`/article/${newsId}`),
  // editNews: (newsId: string, data: any) =>
  //   httpAuth.put(`/article/update/${newsId}`, data),
  // changePublishedNews: (newsId: any, isPublished: any) =>
  //   httpAuth.post(`/article/change-publish/${newsId}`, {
  //     isPublished,
  //   }),
  // deleteMultipleInvestor: (data: any[]) =>
  //   httpAuth.post(ENDPOINT_API.investorDeleteMany, {
  //     listInvestorId: data,
  //   }),
  // getAllInvestor: (
  //   page: number,
  //   size: number,
  //   publishedDate: string,
  //   searchValue: string,
  //   isPublish: string | number,
  //   createdBy: string,
  //   typeInvestor: string,
  // ) =>
  //   httpAuth.get(ENDPOINT_API.investorListAll, {
  //     params: {
  //       page,
  //       size,
  //       publishedDate,
  //       searchValue,
  //       isPublish,
  //       createdBy,
  //       typeInvestor,
  //     },
  //   }),
  // downLoadTemplateInvestor: () => httpAuth.get(ENDPOINT_API.investorTemplate),
  // importInvestor: (data: any) =>
  //   httpAuth.post(ENDPOINT_API.investorImport, data),
  // deleteInvestor: (id: string) =>
  //   http.delete(ENDPOINT_API.investorDeleteInvestor + id, {
  //     params: {
  //       investorId: id,
  //     },
  //     headers: {
  //       Authorization: BearerToken(),
  //     },
  //   }),
  // createNewInvestor: (data: any) => {
  //   return httpAuth.post(ENDPOINT_API.createNewInvestor, data);
  // },
  // editInvestor: (investorId: any, data: any) =>
  //   httpAuth.put(`${ENDPOINT_API.editInvestor}${investorId}`, data),
  // getInvestorById: (id: string) =>
  //   httpAuth.get(`${ENDPOINT_API.investorGetById}${id}`),
  // getInvestorByName: (body: any) =>
  //   httpAuth.post(`${ENDPOINT_API.investorGetById}`, {
  //     ...body,
  //   }),
  // uploadImageInvestor: (formData: any, investorId: string, type: string) =>
  //   httpAuth.post(
  //     `${ENDPOINT_API.investorUploadImage}${investorId}?type=${type}`,
  //     formData,
  //   ),
  // getInvestorTypeListAllTree: () =>
  //   httpAuth.get(ENDPOINT_API.investorTypeListAllTree),
  // createInvestorType: (data: any) =>
  //   httpAuth.post(ENDPOINT_API.investorTypeCreateNew, data),
  // getAreaList: (size: number) =>
  //   httpAuth.get(ENDPOINT_API.areasListAll, {
  //     params: {
  //       size,
  //     },
  //   }),
  // getIndustryList: (size: number) =>
  //   httpAuth.get(ENDPOINT_API.industriesListAll, {
  //     params: {
  //       size,
  //     },
  //   }),
  // getPersonList: (page: number, size: number, keySearch = "") =>
  //   httpAuth.get(ENDPOINT_API.personListName, {
  //     params: {
  //       page,
  //       size,
  //       keySearch,
  //     },
  //   }),
  // getTransactionList: (page: number, size: number, keySearch = "") =>
  //   httpAuth.get(ENDPOINT_API.transactionListName, {
  //     params: {
  //       page,
  //       size,
  //       keySearch,
  //     },
  //   }),
  // changePublishedPerson: (personId: any, isPublished: any) =>
  //   httpAuth.post(`/person/change-publish/${personId}`, {
  //     isPublished,
  //   }),
  // getAllInsight: (
  //   publishedDate: string,
  //   keySearch: string,
  //   page: number,
  //   size: number,
  //   status: any,
  // ) =>
  //   httpAuth.get(ENDPOINT_API.insightListAll, {
  //     params: {
  //       publishedDate,
  //       keySearch,
  //       page,
  //       size,
  //       status,
  //     },
  //   }),
  // createInsight: (data: any) =>
  //   httpAuth.post(ENDPOINT_API.insightCreateNew, data),
  // uploadImageInsight: (insightId: string, file: any) =>
  //   httpAuth.post(`/insight/upload-image/${insightId}`, file),
  // deleteInsight: (insightId: string) =>
  //   httpAuth.delete(`/insight/delete/${insightId}`),
  // deleteManyInsight: (data: any[]) =>
  //   httpAuth.post(ENDPOINT_API.insightDeleteMany, {
  //     listInsightId: data,
  //   }),
  // getInsightById: (insightId: string) => httpAuth.get(`/insight/${insightId}`),
  // editInsight: (insightId: string, data: any) =>
  //   httpAuth.put(`/insight/update/${insightId}`, data),
  // changePublishedInsight: (insightId: any, isPublished: any) =>
  //   httpAuth.put(`/insight/change-status/${insightId}`, {
  //     isPublished,
  //   }),
  // uploadPDFInsight: (insightId: string) =>
  //   httpAuth.post(`/insight/upload-pdf/${insightId}`),
  // emptyPDFInsight: (insightId: string) =>
  //   httpAuth.put(`/insight/empty-pdf/${insightId}`),
  // changePublishedInvestor: (investorId: any, isPublished: any) =>
  //   httpAuth.post(`/investor/change-publish/${investorId}`, {
  //     isPublished,
  //   }),
  // getAllAnalysis: (
  //   keySearch: string,
  //   publishedDate: string,
  //   page: number,
  //   size: number,
  //   isPublished: any,
  // ) =>
  //   httpAuth.get(ENDPOINT_API.analysisListAll, {
  //     params: {
  //       keySearch,
  //       publishedDate,
  //       page,
  //       size,
  //       isPublished,
  //     },
  //   }),
  // createAnalysis: (data: any) =>
  //   httpAuth.post(ENDPOINT_API.analysisCreateNew, data),
  // deleteAnalysis: (analysisId: string) =>
  //   httpAuth.delete(`/analysis-survey/delete/${analysisId}`),
  // deleteManyAnalysis: (data: any[]) =>
  //   httpAuth.post(ENDPOINT_API.analysisDeleteMany, {
  //     listAnalysisId: data,
  //   }),
  // getAnalysisById: (analysisId: string) =>
  //   httpAuth.get(`/analysis-survey/get/${analysisId}`),
  // editAnalysis: (analysisId: string, data: any) =>
  //   httpAuth.put(`/analysis-survey/update/${analysisId}`, data),
  // changePublishedAnalysis: (analysisId: any, isPublished: any) =>
  //   httpAuth.post(`/analysis-survey/change-published/${analysisId}`, {
  //     isPublished,
  //   }),
  // createVideo: (data: any) => httpAuth.post(ENDPOINT_API.createVideo, data),
  // uploadVideo: (videoId: string) =>
  //   httpAuth.post(`${ENDPOINT_API.uploadVideo}/${videoId}`),
  // uploadThumbnail: (videoId: string, file: any) =>
  //   httpAuth.post(`${ENDPOINT_API.uploadThumbnail}/${videoId}`, file),
  // emptyVideo: (videoId: string) =>
  //   httpAuth.post(`${ENDPOINT_API.emptyVideo}/${videoId}`),
  // preSignedURL: (path: string, formData: any) =>
  //   axios.put(path, formData, {
  //     headers: {
  //       "Content-Type": "application/pdf",
  //     },
  //   }),
  // getAllListVideo: (
  //   page: number,
  //   size: number,
  //   keySearch: string,
  //   isPublished: any,
  //   publishedDate: string,
  // ) =>
  //   httpAuth.get(ENDPOINT_API.videoListAll, {
  //     params: {
  //       keySearch,
  //       page,
  //       size,
  //       isPublished,
  //       publishedDate,
  //     },
  //   }),
  // changePublishedVideo: (videoId: string, isPublished: any) =>
  //   httpAuth.put(`${ENDPOINT_API.changeStatusVideo}/${videoId}`, {
  //     isPublished,
  //   }),
  // deleteManyVideo: (body: any) =>
  //   httpAuth.post(ENDPOINT_API.videoDeleteMany, {
  //     ...body,
  //   }),
  // deleteVideo: (videoId: string) =>
  //   httpAuth.delete(`${ENDPOINT_API.videoDelete}/${videoId}`),
  // getVideoById: (videoId: string) =>
  //   httpAuth.get(`${ENDPOINT_API.videoDetail}/${videoId}`),
  // updateVideo: (videoId: string, data: any) =>
  //   httpAuth.put(`${ENDPOINT_API.videoUpdate}/${videoId}`, data),
  // getAllPaymentHistory: (
  //   page: number,
  //   size: number,
  //   keySearch: string,
  //   publishedDate: string,
  //   status: string,
  // ) =>
  //   httpAuth.get(ENDPOINT_API.paymentHistoryListAll, {
  //     params: {
  //       keySearch,
  //       publishedDate,
  //       page,
  //       size,
  //       status,
  //     },
  //   }),
  // uploadImageMarkdown: (formData: any) =>
  //   httpAuth.post(`/article/upload-free-file`, formData),
  // importFundingRound: (data: any) =>
  //   httpAuth.post(`funding-round/import`, data),
  // listEmailCMSUser: (page: number, size: number) =>
  //   httpAuth.get(`user/list-email?page=${page}&size=${size}`),
  // listRoleLPUser: (keySearch: string, page: number, size: number) =>
  //   httpAuth.get(
  //     `${ENDPOINT_API.roleLPList}?keySearch=${keySearch}&page=${page}&size=${size}`,
  //   ),
  // listRoleSelectLPUser: (keySearch: string, page: number, size: number) =>
  //   httpAuth.get(
  //     `${ENDPOINT_API.roleLPListSelect}?keySearch=${keySearch}&page=${page}&size=${size}`,
  //   ),
  // createRoleLPUser: (data: any) =>
  //   httpAuth.post(`${ENDPOINT_API.roleLPCreate}`, data),
  // getDetailRoleLPUser: (roleId: any) =>
  //   httpAuth.get(`${ENDPOINT_API.roleLPDetail}/${roleId}`),
  // editDetailRoleLPUser: (roleId: any, data: any) =>
  //   httpAuth.put(`${ENDPOINT_API.roleLPEdit}/${roleId}`, data),
  // deleteRoleLPUser: (roleId: any) =>
  //   httpAuth.delete(`${ENDPOINT_API.roleLPDelete}/${roleId}`),
  // changeRoleLPUser: (roleId: any, data: any) =>
  //   httpAuth.put(`/user/edit-lp/${roleId}`, data),
  // getListResourceRoleLPUser: (page: number, size: number) =>
  //   httpAuth.get(`/resource-lp/list`, {
  //     params: {
  //       page,
  //       size,
  //     },
  //   }),
  // getListCountry: (page: number, size: number, keySearch: string) =>
  //   httpAuth.get(`/country/list-name`, {
  //     params: {
  //       page,
  //       size,
  //       keySearch,
  //     },
  //   }),
  // getListRegion: (
  //   countryName: string,
  //   page: number,
  //   size: number,
  //   keySearch: string,
  // ) =>
  //   httpAuth.get(`/region/list-name?countryName=${countryName}`, {
  //     params: {
  //       page,
  //       size,
  //       keySearch,
  //     },
  //   }),
  // getAllCountry: (page: number, size: number, keySearch: string) =>
  //   httpAuth.get(`${ENDPOINT_API.country}/get-all`, {
  //     params: {
  //       page,
  //       size,
  //       keySearch,
  //     },
  //   }),
  // getDetailCountry: (countryId: string) =>
  //   httpAuth.get(`${ENDPOINT_API.country}/get-detail/${countryId}`),
  // createCountry: (data: any) =>
  //   httpAuth.post(`${ENDPOINT_API.country}/create-new`, data),
  // deleteSingleCountry: (countryId: string) =>
  //   httpAuth.delete(`${ENDPOINT_API.country}/delete/${countryId}`),
  // editCountry: (countryId: string, data: any) =>
  //   httpAuth.put(`${ENDPOINT_API.country}/update/${countryId}`, data),
  // deleteManyCountries: (data: any[]) =>
  //   httpAuth.post(`${ENDPOINT_API.country}/delete-many`, {
  //     listId: data,
  //   }),
  // getAllRegion: (
  //   page: number,
  //   size: number,
  //   keySearch: string,
  //   countryName: string,
  // ) =>
  //   httpAuth.get(`${ENDPOINT_API.region}/get-all`, {
  //     params: {
  //       page,
  //       size,
  //       keySearch,
  //       countryName,
  //     },
  //   }),
  // getDetailRegion: (regionId: string) =>
  //   httpAuth.get(`${ENDPOINT_API.region}/get-detail/${regionId}`),
  // createRegion: (data: any) =>
  //   httpAuth.post(`${ENDPOINT_API.region}/create-new`, data),
  // deleteSingleRegion: (regionId: string) =>
  //   httpAuth.delete(`${ENDPOINT_API.region}/delete/${regionId}`),
  // editRegion: (regionId: string, data: any) =>
  //   httpAuth.put(`${ENDPOINT_API.region}/update/${regionId}`, data),
  // deleteManyRegions: (data: any[]) =>
  //   httpAuth.post(`${ENDPOINT_API.region}/delete-many`, {
  //     listId: data,
  //   }),
  // changePublishedTransaction: (acquisitionId: any, isPublished: any) =>
  //   httpAuth.post(`/acquisition/change-publish/${acquisitionId}`, {
  //     isPublished,
  //   }),
  // uploadWidgetImage: (widgetId: string, file: any) =>
  //   httpAuth.post(`/analysis-survey/upload-file/${widgetId}`, file),
  // getListPermission: () => httpAuth.get(`/permission/list`),
  // getTotalBalance: () => httpAuth.get(`/dashboard/payment_statistics`),
  // getCrmStatistics: () => httpAuth.get(`/dashboard/crm_statistics`),
  // getCrmStatisticsUser: () => httpAuth.get(`/dashboard/crm_statistics_user`),
};

export default API;
