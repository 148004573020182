import { useEffect } from "react";
import { message } from "antd";

const NotificationCustom = (props: any) => {
  const [messageApi, contextHolder] = message.useMessage();
  useEffect(() => {
    messageApi.open({
      type: props.type,
      content: props.content,
    });
  }, []);
  return <>{contextHolder}</>;
};
export default NotificationCustom;
