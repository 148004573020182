import { createSlice } from "@reduxjs/toolkit";

export interface SingleAdminState {
  data: {
    nguoiDungId: number;
    taiKhoan: string;
    tenNguoiDung: string;
    gioiTinh: number;
    ngaySinh: string;
    soCanCuoc: string;
    email: string;
    sdt: string;
    trangThai: string;

    // username: string;
    // phone: string;
    // status: number;
    // fullName: "";
    // website: "";
    // avatar: string;
    // createdAt: string;
    // updatedAt: string;
    // roles: [
    //   {
    //     roleId: number;
    //     roleName: string;
    //   },
    // ];
  };
  loading: boolean;
  error: any;
}

const initialState: SingleAdminState = {
  data: {
    nguoiDungId: 0,
    taiKhoan: "",
    tenNguoiDung: "",
    gioiTinh: 0,
    ngaySinh: "",
    soCanCuoc: "",
    email: "",
    sdt: "",
    trangThai: "",

    // username: "",
    // phone: "",
    // status: 0,
    // avatar: "",
    // fullName: "",
    // website: "",
    // createdAt: "",
    // updatedAt: "",
    // roles: [
    //   {
    //     roleId: 0,
    //     roleName: "",
    //   },
    // ],
  },
  loading: false,
  error: {},
};

export const Slice = createSlice({
  name: "singleAdmin",
  initialState,
  reducers: {
    clearAdminAvatar: (state) => {
      // state.data.avatar = "";
    },
    singleAdminPending: (state) => {
      state.loading = true;
    },
    singleAdminSuccess: (state, action) => {
      state.data = Object.assign(state.data, action.payload.data);
      state.loading = false;
      state.error = {};
    },
    singleAdminError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  singleAdminPending,
  singleAdminSuccess,
  singleAdminError,
  clearAdminAvatar,
} = Slice.actions;

export default Slice.reducer;

