import axios from "axios";
import { BearerToken } from "@configs/api";
import { API_STATUS_TEXT } from "@utilities/constant";
import ENVIRONMENT_CONFIG from "@configs/env";
import { HTTP_STATUS_CODE } from "@ts/enums";

export const httpAuth = axios.create({
  baseURL: ENVIRONMENT_CONFIG.host,
  timeout: 180000,
  headers: {
    "Content-Type": "application/json",
  },
});

httpAuth.interceptors.request.use(
  async (config) => {
    config.headers.Authorization = BearerToken();

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

httpAuth.interceptors.response.use(
  (response) => response,
  (error) => {
    const { config, response } = error;
    if (
      config &&
      response &&
      response.status === HTTP_STATUS_CODE.UNAUTHORIZED &&
      response.statusText === API_STATUS_TEXT.unauthorized
    ) {
      localStorage.removeItem("token");
      window.location.href = "/login";
      return Promise.reject(error);
    }
    return Promise.reject(error);
  },
);
