import company from "./company.png";
import login from "./login.jpg";
import logo from "./logo.svg";
import person from "./person.png";
import breakline from "./breakline-markdown-toolbar.svg";
import linkNewTab from "./open-link-newtab-markdown-toolbar.svg";
import dashboardIcon from "./sidebar/dashboard-icon.svg";
import userManagerIcon from "./sidebar/user-manager-icon.svg";
import roleManagerIcon from "./sidebar/role-manager-icon.svg";
import companyIcon from "./sidebar/company-icon.svg";
import personIcon from "./sidebar/person-icon.svg";
import transactionIcon from "./sidebar/transaction-icon.svg";
import newsIcon from "./sidebar/news-icon.svg";
import investorIcon from "./sidebar/investor-icon.svg";
import insightIcon from "./sidebar/insight-icon.svg";
import analysisIcon from "./sidebar/analysis-icon.svg";
import videoIcon from "./sidebar/video-icon.svg";
import uploadVideoIcon from "./sidebar/upload-video-icon.jpg";
import paymentIcon from "./sidebar/payment-icon.svg";

const IMAGES = {
  company: company,
  login: login,
  logo: logo,
  person: person,
  breakline: breakline,
  linkNewTab: linkNewTab,
  dashboardIcon: dashboardIcon,
  userManagerIcon: userManagerIcon,
  roleManagerIcon: roleManagerIcon,
  companyIcon: companyIcon,
  personIcon: personIcon,
  transactionIcon: transactionIcon,
  newsIcon: newsIcon,
  investorIcon: investorIcon,
  insightIcon: insightIcon,
  analysisIcon: analysisIcon,
  videoIcon: videoIcon,
  uploadVideoIcon: uploadVideoIcon,
  paymentIcon: paymentIcon,
};

export default IMAGES;
