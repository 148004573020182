import ENVIRONMENT_CONFIG from "@configs/env";

export const API_STATUS_TEXT = {
  unauthorized: "Unauthorized",
};

export const CHARACTER_SPECIAL =
  "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";

export const areasType = {
  AREA: "AREA",
  HEAD_QUARTER: "HEAD_QUARTER",
  OPERATION_AREA: "OPERATION_AREA",
};

export const PERSON_TYPE = {
  FOUNDER: "FOUNDER",
  MEMBER: "MEMBER",
  PRIMARY: "PRIMARY",
  MEMBER_PRIMARY: "MEMBER_PRIMARY",
  PRIMARY_FOUNDER: "PRIMARY_FOUNDER",
  MEMBER_FOUNDER: "MEMBER_FOUNDER",
  MEMBER_PRIMARY_FOUNDER: "MEMBER_PRIMARY_FOUNDER",
  BOTH: "BOTH",
  HEAD_QUARTER: "HEAD_QUARTER",
};

export const GENDER = [
  {
    value: "MALE",
    label: "Male",
  },
  {
    value: "FEMALE",
    label: "Female",
  },
  {
    value: "OTHER",
    label: "Other",
  },
];

export const COMPANY = {
  SINGLE: 1,
  DELETE_SUCCESS: "Delete successfully",
  DELETE_ERROR: "Delete company failed",
};

export const NEWS = {
  SINGLE: 1,
  REMOVED: "removed",
  UPLOADING: "uploading",
  NONE: "none",
  UPDATE_SUCCESS: "You have update successfully!",
  UPDATE_ERROR: "Something Wrong, Please try again!",
  DELETE: "Delete article/news successfully!",
  DELETE_MANY: "Delete many articles/news successfully!",
  DELETE_ERROR: "Delete article/news failed",
  ERROR_DOWNLOAD: "Download file error",
  SUCCESS_DOWNLOAD: "Download file success",
};

export const COUNTRY = {
  SINGLE: 1,
  CREATE: "Create country successfully!",
  UPDATE_SUCCESS: "You have update successfully!",
  UPDATE_ERROR: "Something Wrong, Please try again!",
  DELETE: "Delete country successfully!",
  DELETE_MANY: "Delete many countries successfully!",
  DELETE_ERROR: "Delete country failed!",
  DELETE_ERROR_ASSIGNED: "Country has been assigned to other company.",
};

export const REGION = {
  SINGLE: 1,
  CREATE: "Create region successfully!",
  UPDATE_SUCCESS: "You have update successfully!",
  UPDATE_ERROR: "Something Wrong, Please try again!",
  DELETE: "Delete region successfully!",
  DELETE_MANY: "Delete many regions successfully!",
  DELETE_ERROR: "Delete region failed!",
  DELETE_ERROR_ASSIGNED: "Region has been assigned to other company.",
  REQUIRED_MESSAGE: "This field is required!",
};

export const AREA = {
  SINGLE: 1,
  DELETE: "Delete area successfully",
  DELETE_MANY: "Delete many areas successfully",
  DELETE_ERROR: "Delete area failed",
  DELETE_ERROR_ASSIGNED: "Area has been assigned to other company",
};

export const INDUSTRY = {
  SINGLE: 1,
  DELETE: "Delete industry successfully",
  DELETE_MANY: "Delete many industries successfully",
  DELETE_ERROR: "Delete industry failed",
  DELETE_ERROR_ASSIGNED: "Industry has been assigned to other company",
};

export const PERSON = {
  SINGLE: 1,
  REMOVED: "removed",
  UPLOADING: "uploading",
  NONE: "none",
  UPDATE_SUCCESS: "You have update successfully!",
  UPDATE_ERROR: "Something Wrong, Please try again!",
  DELETE: "Delete person successfully",
  DELETE_MANY: "Delete people successfully",
  DELETE_ERROR: "Delete person fail",
  ERROR_DOWNLOAD: "Download file error",
  SUCCESS_DOWNLOAD: "Download file success",
  TITLE_DELETE: "Confirm Delete",
};

export const TRANSACTION = {
  SINGLE: 1,
  REMOVED: "removed",
  UPLOADING: "uploading",
  NONE: "none",
  UPDATE_SUCCESS: "You have update successfully!",
  UPDATE_ERROR: "Something Wrong, Please try again!",
  DELETE: "Delete transaction successfully!",
  DELETE_MANY: "Delete many transactions successfully!",
  DELETE_ERROR: "Delete transaction fail!",
  ERROR_DOWNLOAD: "Download file error",
  SUCCESS_DOWNLOAD: "Download file success",
  PRICE: "USD",
  NOT_HAVE_PERMISSION_CREATE_COMPANY:
    "You don't have permission for create company.",
};

export const INVESTOR = {
  SINGLE: 1,
  DELETE_SUCCESS: "Delete successfully",
  DELETE_ERROR: "Delete investor failed",
  MESSAGE_CREATE_SUCCESS: "Created investor successfully!",
  MESSAGE_CREATE_FAIL: "Created investor fail!",
  MESSAGE_UPLOAD_IMAGE_FAIL: "Upload image fail!",
  MESSAGE_ERROR: "ERROR",
};

export const FIRST_PAGE = 1;
export const FIRST_SIZE = 10;
export const INFINITY_PAGE_SIZE = 30000;
export const FORMAT_DATE = "MM/DD/YYYY, h:mm:ss A";
export const MAX_LENGTH_USER_FULLNAME = 30;
export const DATE_FORMAT = "MM/DD/YYYY";
export const DATE_FORMAT_HH_MM_SS_A = "HH:mm:ss A";

export const REGEX = {
  CHARACTER_NAME: /^[\p{L}\s]*$/u,
  NAME: /^(?!\s)[\p{L}\p{M}\s]{1,}$/u,
  URL: /^(https?|ftp):\/\/[^\s/$.?#].\S*$/i,
  PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/g,
};
export const PASSWORD_MESSAGE =
  "Password must contain minimum 8 characters, at least one uppercase letter (A-Z), one lowercase letter (a-z), one number (0-9) and one special character.";

export const NUMBER_EMPLOYEE = [
  {
    name: "1-10",
    value: "1-10",
  },
  {
    name: "11-50",
    value: "11-50",
  },
  {
    name: "51-100",
    value: "51-100",
  },
  {
    name: "101-250",
    value: "101-250",
  },
  {
    name: "251-500",
    value: "251-500",
  },
  {
    name: "501-1000",
    value: "501-1000",
  },
  {
    name: "1001-5000",
    value: "1001-5000",
  },
  {
    name: "5001-10000",
    value: "5001-10000",
  },
  {
    name: "10001+",
    value: "10001+",
  },
];
export const OPERATING_STATUS = [
  {
    name: "Active",
    value: true,
  },
  {
    name: "Inactive",
    value: false,
  },
  {
    name: "N/A",
    value: null,
  },
];
export const IPO_STATUS = [
  {
    name: "Public",
    value: "Public",
  },
  {
    name: "Private",
    value: "Private",
  },
  {
    name: "Delisted",
    value: "Delisted",
  },
  {
    name: "N/A",
    value: null,
  },
];

export const IPO_STATUS_NAME = {
  public: "Public",
  private: "Private",
  delisted: "Delisted",
  na: null,
};
export const TABLE_LAYOUT_OPTIONS = {
  emptyText: " ",
};
export const NUMBER_PATTERN = /\n$/;
export const REGEX_CHECK_KEY_DOWN = /^\d$/;
export const BACKSPACE_KEY = "Backspace";
export const USER_CONST = {
  EMAIL_EXISTED_MESSAGE: "Email is existed!",
  UPDATE_SUCCESS_MESSAGE: "You have updated successfully!",
  TYPE_SUCCESS: "success",
  DEFAULT_FULLNAME: "Admin",
};
export const ROLE_CONST = {
  ROLE_EXISTED_MESSAGE: "Role is existed!",
};

export const NOT_HAVE_PERMISSION = "Not have Permission!";
export const UPDATE_CONST = {
  MESSAGE_SUCCESS: "You have updated successfully!",
  MESSAGE_ERROR: "Error when updating!",
  TYPE_SUCCESS: "success",
  TYPE_ERROR: "error",
};
export const USER_MANUAL_GUIDE =
  "https://dev-doublefeather-static.s3.ap-southeast-1.amazonaws.com/User+Manual+guide.pdf";

export const PAGE_SIZE_OPTIONS = ["10", "20", "50", "100"];
export const DEFAULT_PAGE = 1;
export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_CURRENT_MONEY = "$";

export const PUBLISH_DRAFT = {
  MESSAGE_SUCCESS_PUBLISH: "Change published successfully!",
  MESSAGE_SUCCESS_DRAFT: "Change draft successfully!",
  MESSAGE_ERROR_PUBLISH: "Change published fail!",
  MESSAGE_ERROR_DRAFT: "Change draft fail!",
};

export const ERROR_DELETE_ROLE_IN_USED = "Cant delete role in used!";

export const LINK = {
  http: "http://",
  https: "https://",
};
export const LINK_MAX_LENGTH = 100;
export const changeLinkTooLong = (link: string) => {
  if (link?.length > LINK_MAX_LENGTH) {
    return `${link.slice(0, LINK_MAX_LENGTH)}...`;
  } else {
    return link;
  }
};
export const LANDING_PAGE_DOMAIN_ADDRESS =
  ENVIRONMENT_CONFIG.LANDING_PAGE_DOMAIN_ADDRESS;

export const LANDING_PAGE_DOMAIN_ADDRESS_NAME = {
  news: `${LANDING_PAGE_DOMAIN_ADDRESS}news/`,
};

export const CHARACTER_VN =
  "àáãảạăằắẳẵặâầấẩẫậèéẻẽẹêềếểễệđùúủũụưừứửữựòóỏõọôồốổỗộơờớởỡợìíỉĩịäëïîöüûñçýỳỹỵỷ";
export const CHARACTER_EN =
  "aaaaaaaaaaaaaaaaaeeeeeeeeeeeduuuuuuuuuuuoooooooooooooooooiiiiiaeiiouuncyyyyy";
export const characterVnToEn = (keySearch: string) => {
  for (let i = 0, l = CHARACTER_VN.length; i < l; i++) {
    keySearch = keySearch.replace(
      RegExp(CHARACTER_VN[i], "gi"),
      CHARACTER_EN[i],
    );
  }
  keySearch = keySearch
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9-]/g, "-")
    .replace(/-+/g, "-");
  return keySearch;
};

export const REGEX_PATTERN = {
  EMAIL_PATTERN: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
  URL_PATTERN: /^https?:\/\/.*/,
  YEAR_PATTERN: /^[1-9]\d{3}$/,
};

export const ESG_INVESTOR = [
  {
    label: "Yes",
    value: true,
  },
  {
    label: "No",
    value: false,
  },
];

export const STYLE_DISABLE_SUBMIT_BUTTON =
  "cursor: not-allowed; pointer-events: none; color: rgba(0,0,0,.25); background: #f5f5f5; border-color: #d9d9d9; text-shadow: none; box-shadow: none";
export const STYLE_ENABLE_SUBMIT_BUTTON =
  "color: #fff; border-color: #1890ff; background: #1890ff; text-shadow: 0 -1px 0 rgba(0,0,0,.12); box-shadow: 0 2px 0 rgba(0,0,0,.045)";
export const emailAdministrator = "doublefeather@gmail.com";
export const MESSAGE_FORM_INVESTOR = {
  messageRequired: "This field is required",
  messageEmail: "This is not a valid email address",
  messageUrl: "This is not a valid URL",
  messageUrlHttp:
    "This is not a valid URL. The URL you entered is invalid (URLs must start with http:// or https://)",
};
export const INVESTMENT_STAGE_DATA = [
  {
    label: "Seed",
    value: "Seed",
  },
  {
    label: "Early",
    value: "Early",
  },
  {
    label: "Growth",
    value: "Growth",
  },
  {
    label: "Pre IPO",
    value: "Pre IPO",
  },
  {
    label: "Established",
    value: "Established",
  },
];

export const FUND_SIZE_DATA = [
  {
    label: "-5MM",
    value: "-5MM",
  },
  {
    label: "-25MM",
    value: "-25MM",
  },
  {
    label: "-100MM",
    value: "-100MM",
  },
  {
    label: "-1Bn",
    value: "-1Bn",
  },
  {
    label: "1Bn-",
    value: "1Bn-",
  },
];

export const TICKET_SIZE_DATA = [
  {
    label: "50K",
    value: "50K",
  },
  {
    label: "-100K",
    value: "-100K",
  },
  {
    label: "-500K",
    value: "-500K",
  },
  {
    label: "-1MM",
    value: "-1MM",
  },
  {
    label: "-5MM",
    value: "-5MM",
  },
  {
    label: "-10MM",
    value: "-10MM",
  },
  {
    label: "-50MM",
    value: "-50MM",
  },
  {
    label: "-100MM",
    value: "-100MM",
  },
  {
    label: "100MM-",
    value: "100MM-",
  },
];

export const NO_DATA = "no-data";
export const PUBLISH_VALUE = 1;
export const UNPUBLISH_VALUE = 0;
export const UNPUBLISH_VALUE_USER_LP = 2;
export const PUBLISH_LABEL = "Publish";
export const UNPUBLISH_LABEL = "Unpublish";
export const ACTIVE_LABEL = "Active";
export const INACTIVE_LABEL = "Inactive";
export const TRANSACTION_STATUS_TAG: {
  [key: string]: { title: string; color: string };
} = {
  Pending: {
    title: "Pending",
    color: "orange",
  },
  Complete: {
    title: "Complete",
    color: "green",
  },
};

export const INSIGHT = {
  SINGLE: 1,
  REMOVED: "removed",
  UPLOADING: "uploading",
  NONE: "none",
  UPDATE_SUCCESS: "You have update successfully!",
  UPDATE_ERROR: "Something Wrong, Please try again!",
  DELETE: "Delete insight successfully!",
  DELETE_MANY: "Delete many insights successfully!",
  DELETE_ERROR: "Delete insight fail!",
  PRICE: "USD",
};

export const ROLES = { ADMIN: "ADMIN", USER: "USER" };
export const ANALYSIS = {
  SINGLE: 1,
  REMOVED: "removed",
  UPLOADING: "uploading",
  NONE: "none",
  UPDATE_SUCCESS: "You have update successfully!",
  UPDATE_ERROR: "Something Wrong, Please try again!",
  DELETE: "Delete Analysis & Survey successfully!",
  DELETE_MANY: "Delete many Analysis & Surveys successfully!",
  DELETE_ERROR: "Delete Analysis & Survey fail!",
  PRICE: "USD",
};
export const PUBLISH_STATUS = {
  default: "",
  published: true,
  noPublished: false,
};

export const DOMAIN_INISGHT_PDF_ADDRESS =
  "https://dev-doublefeather-static.s3.ap-southeast-1.amazonaws.com/insight/pdf/";

export const getClickableLink = (link: any) => {
  return link?.startsWith(LINK.http) || link?.startsWith(LINK.https)
    ? link
    : ENVIRONMENT_CONFIG.S3_URL + link;
};

export const VIDEO = {
  SINGLE: 1,
  REMOVED: "removed",
  UPLOADING: "uploading",
  NONE: "none",
  UPDATE_SUCCESS: "You have update successfully!",
  UPDATE_ERROR: "Something Wrong, Please try again!",
  DELETE: "Delete video successfully",
  DELETE_MANY: "Delete video successfully",
  DELETE_ERROR: "Delete video fail",
  ERROR_DOWNLOAD: "Download file error",
  SUCCESS_DOWNLOAD: "Download file success",
  TITLE_DELETE: "Confirm Delete",
  UPLOAD_VIDEO_FAIL: "Upload video fail",
  UPLOAD_THUMBNAIL_FAIL: "Upload thumbnail fail",
  CREATE_VIDEO_FAIL: "Create video fail",
};

export const USER_CMS_FILTER_STATUS = {
  default: "",
  active: 1,
  inactive: 2,
};
export const REGEX_PRICE_INSIGHT = /^(?!0\d)\d{1,6}$/;
export const REGEX_PRICE_INSIGHT_MESSAGE =
  "Price range from 1 to 6 digits (accept zero values but do not accept values starting with 0 )";

export const PAYMENT_STATUS: {
  [key: string]: { value: string; label: string; color: string };
} = {
  UNPAID: { value: "UNPAID", label: "Unpaid", color: "cyan" },
  PENDING: { value: "PENDING", label: "Pending", color: "gold" },
  COMPLETE: { value: "COMPLETE", label: "Complete", color: "green" },
  FAILED: { value: "FAILED", label: "Failed", color: "red" },
  CANCEL: { value: "CANCEL", label: "Cancel", color: "purple" },
};

export const PAYMENT_TYPE = {
  MONTH: "month",
  YEAR: "year",
};

export const INSIGHT_TITLE_MAX_LENGTH = 250;
export const INSIGHT_TITLE_MAX_LENGTH_MESSAGE = `Max length of title is ${INSIGHT_TITLE_MAX_LENGTH} character`;
export const TYPE_SORT_TREE = "title";
export const IS_MOST_USED = 1;

export const TYPE_UPLOAD_COMPANY = {
  COMPANY: "company",
  ONE_PAGER: "one-pager",
  ONE_PAGER_ONE: "image-op-one",
  ONE_PAGER_TWO: "image-op-two",
};

export const TYPE_INVESTOR = {
  COMPANY_INVESTOR: "COMPANY_INVESTOR",
  PERSON_INVESTOR: "PERSON_INVESTOR",
  COMPANY_INVESTOR_LINK: "COMPANY_INVESTOR_LINK",
  PERSON_INVESTOR_LINK: "PERSON_INVESTOR_LINK",
};

export const ITEMS_SELECT_INVESTOR: Array<{
  value: string;
  label: string;
}> = [
  {
    value: NO_DATA,
    label: "All Type",
  },
  {
    value: TYPE_INVESTOR.COMPANY_INVESTOR,
    label: "Investment Firm",
  },
  {
    value: TYPE_INVESTOR.PERSON_INVESTOR,
    label: "Individual Investor",
  },
];

export const INVESTMENT = "Investment Firm";
export const INDIVIDUAL = "Individual Investor";
export const INVALID_VALUE = "Invalid File";

export const CMS_USER_ROLE_INDEX = {
  VIEW: 4,
  CREATE: 1,
  UPDATE: 2,
  DELETE: 3,
  PUBLISH: 6,
};

export const INVALID_VALUES = [null, "", undefined];

export const LIST_CURRENT_COMPANY = [
  PERSON_TYPE.MEMBER_PRIMARY_FOUNDER,
  PERSON_TYPE.MEMBER_PRIMARY,
  PERSON_TYPE.MEMBER_FOUNDER,
  PERSON_TYPE.MEMBER,
];
export const LIST_PRIMARY_COMPANY = [
  PERSON_TYPE.MEMBER_PRIMARY_FOUNDER,
  PERSON_TYPE.MEMBER_PRIMARY,
  PERSON_TYPE.PRIMARY_FOUNDER,
  PERSON_TYPE.PRIMARY,
];
export const LIST_FOUNDER_COMPANY = [
  PERSON_TYPE.MEMBER_PRIMARY_FOUNDER,
  PERSON_TYPE.PRIMARY_FOUNDER,
  PERSON_TYPE.MEMBER_FOUNDER,
  PERSON_TYPE.FOUNDER,
];

export const GAPMINDER_URL = "gapminder.org";
export const TABLEAU_URL = "tableau.com";

export const SOURCE_WIDGET_DEFAULT = [
  {
    label: "Gapminder",
    value: "Gapminder",
    index: "1",
  },
  {
    label: "Tableau",
    value: "Tableau",
    index: "2",
  },
  {
    label: "Other",
    value: "Other",
    index: "3",
  },
];

export const INVESTOR_NAME = [
  {
    value: "1",
    label: "All on",
  },
  {
    value: "2",
    label: "Blue Earth Capital",
  },
  {
    value: "3",
    label: "AFR100",
  },
];

export const LIST_IMAGES = [
  {
    key: "1",
    value: "All Images",
  },
  {
    key: "2",
    value: "Uploaded to this post",
  },
  {
    key: "3",
    value: "Document",
  },
  {
    key: "4",
    value: "Spreadsheets",
  },
  {
    key: "5",
    value: "Archives",
  },
  {
    key: "6",
    value: "Unattached",
  },
  {
    key: "7",
    value: "Mine",
  },
];

export const LIST_DATES = [
  {
    key: "1",
    value: "All dates",
  },
];

export const LIST_IMAGES_FI = [
  {
    key: "1",
    value: "Images",
  },
  {
    key: "2",
    value: "Uploaded to this post",
  },
  {
    key: "3",
    value: "Unattached",
  },
  {
    key: "4",
    value: "Mine",
  },
];

export const LIST_DATE = [
  {
    key: "1",
    value: "All dates",
  },
  {
    key: "2",
    value: "March 2023",
  },
  {
    key: "3",
    value: "November 2022",
  },
  {
    key: "4",
    value: "October 2022",
  },
  {
    key: "5",
    value: "September 2022",
  },
  {
    key: "6",
    value: "August 2022",
  },
];

export const TRANSACTION_TYPE = [
  { value: "Acquisition", label: "Acquisition" },
  { value: "Merger", label: "Merger" },
  { value: "Leveraged", label: "Leveraged Buyout" },
  { value: "Acquihire", label: "Acquihire" },
  { value: "Management", label: "Management Buyout" },
];

export const TRANSACTION_STATUS = [
  { value: "Complete", label: "Complete" },
  { value: "Pending", label: "Pending" },
  { value: "Seed", label: "Seed" },
  { value: "Early Stage Venture", label: "Early Stage Venture" },
  { value: "Late Stage Venture", label: "Late Stage Venture" },
  { value: "Private Equity", label: "Private Equity" },
  { value: "M&A", label: "M&A" },
  { value: "IPO", label: "IPO" },
];

export const TRANSACTION_TERMS = [
  { value: "Cash", label: "Cash" },
  { value: "Stock", label: "Stock" },
  { value: "Cash & Stock", label: "Cash & Stock" },
];

export const PREVIEW_PERSON_DATA = {
  articles: [
    {
      id: "04bc4f2f-f7f8-4cd5-8f78-6d882045ebad",
      title: "NewsToday",
      content: null,
      url: "https://www.news.com/",
      type: "QUICK_CREATE",
    },
  ],
  careers: [
    {
      id: "6e18e716-e3d3-49f1-b2de-d78d30085885",
      title: "Very Good",
      content: "Very Good",
      startDate: "2023-06-09T06:55:05.000Z",
      endDate: "2023-07-03T06:55:05.000Z",
    },
  ],
  company: [
    {
      id: "8bf57b8f-fdb9-4ad3-b750-ca3548653867",
      organizationName: "Agriculture Egypt",
    },
    {
      id: "dfcd522c-337b-421f-838a-a511f815d948",
      organizationName: "Galion",
    },
    {
      id: "fa2d4f36-2e87-4e6e-adad-40e71d9b6009",
      organizationName: "Scangroup",
    },
  ],
  createdAt: "2023-06-08T06:55:48.000Z",
  currentCompany: "8bf57b8f-fdb9-4ad3-b750-ca3548653867",
  description: "This is Peter",
  educations: [
    {
      id: "91e99c42-8d1d-4d8d-9eab-d40bf276d364",
      universityName: "RMIT",
      major: "IT",
      startDate: "2023-06-02T06:54:48.000Z",
      endDate: "2023-07-04T06:54:48.000Z",
    },
  ],
  email: "peterdocker@gmail.com",
  experiences: [
    {
      id: "6e18e716-e3d3-49f1-b2de-d78d30085885",
      title: "Very Good",
      content: "Very Good",
      startDate: "2023-06-09T06:55:05.000Z",
      endDate: "2023-07-03T06:55:05.000Z",
    },
  ],
  facebookUrl: "https://www.facebook.com/",
  firstName: "Peter",
  gender: "MALE",
  id: "7bf42db6-0eaf-47d0-af8f-faf682af53b3",
  image:
    "https://dev-doublefeather-static.s3.ap-southeast-1.amazonaws.com/person/thumbnail/z57o2hp8y3p6qdz9d0grx3jmx6004yycq.jpg-tm-login.jpg",
  lastName: "Docker",
  linkedInUrl: "https://www.linkedin.com/",
  name: "Docker Peter",
  news: [
    {
      id: "04bc4f2f-f7f8-4cd5-8f78-6d882045ebad",
      title: "NewsToday",
      content: null,
      url: "https://www.news.com/",
      type: "QUICK_CREATE",
    },
  ],
  nickName: "Peter Docker",
  phoneNumber: "0123456789",
  position: "Executive Director",
  twitterUrl: "https://www.twitter.com/",
  website: "https://www.website.com/",
  isPublished: true,
};

export const PERMISSION_NAME_KEY = {
  create: "create",
  update: "update",
  delete: "delete",
  view: "view",
  publish: "publish",
};

export const PERMISSION_DATA = [
  {
    resourceName: "User management",
    resourceKey: "user_management",
    permissions: [
      {
        permissionName: PERMISSION_NAME_KEY.create,
        permissionKey: PERMISSION_NAME_KEY.create,
      },
      {
        permissionName: PERMISSION_NAME_KEY.update,
        permissionKey: PERMISSION_NAME_KEY.update,
      },
      {
        permissionName: PERMISSION_NAME_KEY.delete,
        permissionKey: PERMISSION_NAME_KEY.delete,
      },
      {
        permissionName: PERMISSION_NAME_KEY.view,
        permissionKey: PERMISSION_NAME_KEY.view,
      },
    ],
  },
  {
    resourceName: "Role management",
    resourceKey: "role_management",
    permissions: [
      {
        permissionName: PERMISSION_NAME_KEY.create,
        permissionKey: PERMISSION_NAME_KEY.create,
      },
      {
        permissionName: PERMISSION_NAME_KEY.update,
        permissionKey: PERMISSION_NAME_KEY.update,
      },
      {
        permissionName: PERMISSION_NAME_KEY.delete,
        permissionKey: PERMISSION_NAME_KEY.delete,
      },
      {
        permissionName: PERMISSION_NAME_KEY.view,
        permissionKey: PERMISSION_NAME_KEY.view,
      },
    ],
  },
  {
    resourceName: "Company",
    resourceKey: "company",
    permissions: [
      {
        permissionName: PERMISSION_NAME_KEY.create,
        permissionKey: PERMISSION_NAME_KEY.create,
      },
      {
        permissionName: PERMISSION_NAME_KEY.update,
        permissionKey: PERMISSION_NAME_KEY.update,
      },
      {
        permissionName: PERMISSION_NAME_KEY.delete,
        permissionKey: PERMISSION_NAME_KEY.delete,
      },
      {
        permissionName: PERMISSION_NAME_KEY.view,
        permissionKey: PERMISSION_NAME_KEY.view,
      },
      {
        permissionName: PERMISSION_NAME_KEY.publish,
        permissionKey: PERMISSION_NAME_KEY.publish,
      },
    ],
  },
  {
    resourceName: "Person",
    resourceKey: "person",
    permissions: [
      {
        permissionName: PERMISSION_NAME_KEY.create,
        permissionKey: PERMISSION_NAME_KEY.create,
      },
      {
        permissionName: PERMISSION_NAME_KEY.update,
        permissionKey: PERMISSION_NAME_KEY.update,
      },
      {
        permissionName: PERMISSION_NAME_KEY.delete,
        permissionKey: PERMISSION_NAME_KEY.delete,
      },
      {
        permissionName: PERMISSION_NAME_KEY.view,
        permissionKey: PERMISSION_NAME_KEY.view,
      },
      {
        permissionName: PERMISSION_NAME_KEY.publish,
        permissionKey: PERMISSION_NAME_KEY.publish,
      },
    ],
  },
  {
    resourceName: "Acquisition",
    resourceKey: "acquisition",
    permissions: [
      {
        permissionName: PERMISSION_NAME_KEY.create,
        permissionKey: PERMISSION_NAME_KEY.create,
      },
      {
        permissionName: PERMISSION_NAME_KEY.update,
        permissionKey: PERMISSION_NAME_KEY.update,
      },
      {
        permissionName: PERMISSION_NAME_KEY.delete,
        permissionKey: PERMISSION_NAME_KEY.delete,
      },
      {
        permissionName: PERMISSION_NAME_KEY.view,
        permissionKey: PERMISSION_NAME_KEY.view,
      },
      {
        permissionName: PERMISSION_NAME_KEY.publish,
        permissionKey: PERMISSION_NAME_KEY.publish,
      },
    ],
  },
  {
    resourceName: "News",
    resourceKey: "news",
    permissions: [
      {
        permissionName: PERMISSION_NAME_KEY.create,
        permissionKey: PERMISSION_NAME_KEY.create,
      },
      {
        permissionName: PERMISSION_NAME_KEY.update,
        permissionKey: PERMISSION_NAME_KEY.update,
      },
      {
        permissionName: PERMISSION_NAME_KEY.delete,
        permissionKey: PERMISSION_NAME_KEY.delete,
      },
      {
        permissionName: PERMISSION_NAME_KEY.view,
        permissionKey: PERMISSION_NAME_KEY.view,
      },
      {
        permissionName: PERMISSION_NAME_KEY.publish,
        permissionKey: PERMISSION_NAME_KEY.publish,
      },
    ],
  },
  {
    resourceName: "Investor",
    resourceKey: "investor",
    permissions: [
      {
        permissionName: PERMISSION_NAME_KEY.create,
        permissionKey: PERMISSION_NAME_KEY.create,
      },
      {
        permissionName: PERMISSION_NAME_KEY.update,
        permissionKey: PERMISSION_NAME_KEY.update,
      },
      {
        permissionName: PERMISSION_NAME_KEY.delete,
        permissionKey: PERMISSION_NAME_KEY.delete,
      },
      {
        permissionName: PERMISSION_NAME_KEY.view,
        permissionKey: PERMISSION_NAME_KEY.view,
      },
      {
        permissionName: PERMISSION_NAME_KEY.publish,
        permissionKey: PERMISSION_NAME_KEY.publish,
      },
    ],
  },
  {
    resourceName: "Funding",
    resourceKey: "funding",
    permissions: [
      {
        permissionName: PERMISSION_NAME_KEY.create,
        permissionKey: PERMISSION_NAME_KEY.create,
      },
      {
        permissionName: PERMISSION_NAME_KEY.update,
        permissionKey: PERMISSION_NAME_KEY.update,
      },
      {
        permissionName: PERMISSION_NAME_KEY.delete,
        permissionKey: PERMISSION_NAME_KEY.delete,
      },
      {
        permissionName: PERMISSION_NAME_KEY.view,
        permissionKey: PERMISSION_NAME_KEY.view,
      },
    ],
  },
  {
    resourceName: "One Pager",
    resourceKey: "onepager",
    permissions: [
      {
        permissionName: PERMISSION_NAME_KEY.create,
        permissionKey: PERMISSION_NAME_KEY.create,
      },
      {
        permissionName: PERMISSION_NAME_KEY.update,
        permissionKey: PERMISSION_NAME_KEY.update,
      },
      {
        permissionName: PERMISSION_NAME_KEY.delete,
        permissionKey: PERMISSION_NAME_KEY.delete,
      },
      {
        permissionName: PERMISSION_NAME_KEY.view,
        permissionKey: PERMISSION_NAME_KEY.view,
      },
    ],
  },
  {
    resourceName: "Analysis/Survey",
    resourceKey: "analysisSurvey",
    permissions: [
      {
        permissionName: PERMISSION_NAME_KEY.create,
        permissionKey: PERMISSION_NAME_KEY.create,
      },
      {
        permissionName: PERMISSION_NAME_KEY.update,
        permissionKey: PERMISSION_NAME_KEY.update,
      },
      {
        permissionName: PERMISSION_NAME_KEY.delete,
        permissionKey: PERMISSION_NAME_KEY.delete,
      },
      {
        permissionName: PERMISSION_NAME_KEY.view,
        permissionKey: PERMISSION_NAME_KEY.view,
      },
      {
        permissionName: PERMISSION_NAME_KEY.publish,
        permissionKey: PERMISSION_NAME_KEY.publish,
      },
    ],
  },
  {
    resourceName: "Insight",
    resourceKey: "insight",
    permissions: [
      {
        permissionName: PERMISSION_NAME_KEY.create,
        permissionKey: PERMISSION_NAME_KEY.create,
      },
      {
        permissionName: PERMISSION_NAME_KEY.update,
        permissionKey: PERMISSION_NAME_KEY.update,
      },
      {
        permissionName: PERMISSION_NAME_KEY.delete,
        permissionKey: PERMISSION_NAME_KEY.delete,
      },
      {
        permissionName: PERMISSION_NAME_KEY.view,
        permissionKey: PERMISSION_NAME_KEY.view,
      },
      {
        permissionName: PERMISSION_NAME_KEY.publish,
        permissionKey: PERMISSION_NAME_KEY.publish,
      },
    ],
  },
  {
    resourceName: "Video Distribution",
    resourceKey: "videoDistribution",
    permissions: [
      {
        permissionName: PERMISSION_NAME_KEY.create,
        permissionKey: PERMISSION_NAME_KEY.create,
      },
      {
        permissionName: PERMISSION_NAME_KEY.update,
        permissionKey: PERMISSION_NAME_KEY.update,
      },
      {
        permissionName: PERMISSION_NAME_KEY.delete,
        permissionKey: PERMISSION_NAME_KEY.delete,
      },
      {
        permissionName: PERMISSION_NAME_KEY.view,
        permissionKey: PERMISSION_NAME_KEY.view,
      },
      {
        permissionName: PERMISSION_NAME_KEY.publish,
        permissionKey: PERMISSION_NAME_KEY.publish,
      },
    ],
  },
  {
    resourceName: "Payment",
    resourceKey: "payment",
    permissions: [
      {
        permissionName: PERMISSION_NAME_KEY.view,
        permissionKey: PERMISSION_NAME_KEY.view,
      },
    ],
  },
  {
    resourceName: "Industry",
    resourceKey: "industry",
    permissions: [
      {
        permissionName: PERMISSION_NAME_KEY.create,
        permissionKey: PERMISSION_NAME_KEY.create,
      },
      {
        permissionName: PERMISSION_NAME_KEY.update,
        permissionKey: PERMISSION_NAME_KEY.update,
      },
      {
        permissionName: PERMISSION_NAME_KEY.delete,
        permissionKey: PERMISSION_NAME_KEY.delete,
      },
      {
        permissionName: PERMISSION_NAME_KEY.view,
        permissionKey: PERMISSION_NAME_KEY.view,
      },
    ],
  },
  {
    resourceName: "Country",
    resourceKey: "country",
    permissions: [
      {
        permissionName: PERMISSION_NAME_KEY.create,
        permissionKey: PERMISSION_NAME_KEY.create,
      },
      {
        permissionName: PERMISSION_NAME_KEY.update,
        permissionKey: PERMISSION_NAME_KEY.update,
      },
      {
        permissionName: PERMISSION_NAME_KEY.delete,
        permissionKey: PERMISSION_NAME_KEY.delete,
      },
      {
        permissionName: PERMISSION_NAME_KEY.view,
        permissionKey: PERMISSION_NAME_KEY.view,
      },
    ],
  },
  {
    resourceName: "Region",
    resourceKey: "region",
    permissions: [
      {
        permissionName: PERMISSION_NAME_KEY.create,
        permissionKey: PERMISSION_NAME_KEY.create,
      },
      {
        permissionName: PERMISSION_NAME_KEY.update,
        permissionKey: PERMISSION_NAME_KEY.update,
      },
      {
        permissionName: PERMISSION_NAME_KEY.delete,
        permissionKey: PERMISSION_NAME_KEY.delete,
      },
      {
        permissionName: PERMISSION_NAME_KEY.view,
        permissionKey: PERMISSION_NAME_KEY.view,
      },
    ],
  },
  {
    resourceName: "CMS user management",
    resourceKey: "cms_user_management",
    permissions: [
      {
        permissionName: PERMISSION_NAME_KEY.create,
        permissionKey: PERMISSION_NAME_KEY.create,
      },
      {
        permissionName: PERMISSION_NAME_KEY.update,
        permissionKey: PERMISSION_NAME_KEY.update,
      },
      {
        permissionName: PERMISSION_NAME_KEY.view,
        permissionKey: PERMISSION_NAME_KEY.view,
      },
    ],
  },
  {
    resourceName: "Landing page user management",
    resourceKey: "lp_user_management",
    permissions: [
      {
        permissionName: PERMISSION_NAME_KEY.view,
        permissionKey: PERMISSION_NAME_KEY.view,
      },
    ],
  },
  {
    resourceName: "CMS role management",
    resourceKey: "cms_role_management",
    permissions: [
      {
        permissionName: PERMISSION_NAME_KEY.create,
        permissionKey: PERMISSION_NAME_KEY.create,
      },
      {
        permissionName: PERMISSION_NAME_KEY.update,
        permissionKey: PERMISSION_NAME_KEY.update,
      },
      {
        permissionName: PERMISSION_NAME_KEY.delete,
        permissionKey: PERMISSION_NAME_KEY.delete,
      },
      {
        permissionName: PERMISSION_NAME_KEY.view,
        permissionKey: PERMISSION_NAME_KEY.view,
      },
    ],
  },
  {
    resourceName: "Landing page role management",
    resourceKey: "lp_role_management",
    permissions: [
      {
        permissionName: PERMISSION_NAME_KEY.view,
        permissionKey: PERMISSION_NAME_KEY.view,
      },
    ],
  },
];
