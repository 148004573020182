import { Spin } from "antd";

const LoadingComponent = ({ size }: any) => {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Spin size={size} />
    </div>
  );
};

export default LoadingComponent;
