import { useEffect, useState } from "react";
import { UserOutlined } from "@ant-design/icons";
import Avatar from "antd/lib/avatar/avatar";
import { useSelector } from "react-redux";
import { RootState } from "@redux/store";
import { getDefValue } from "@utilities/helper";
// import { UserAvatarProps } from "@ts/interfaces";

const UserAvatar = ({ size }: any) => {
  const auth = useSelector((state: RootState) => state.auth);
  const updateAdmin = useSelector((state: RootState) => state.updateAdmin);
  const [image, setImage] = useState<string>(updateAdmin.image);

  useEffect(() => {
    setImage(updateAdmin.image);
  }, [updateAdmin.image]);

  const avatar = getDefValue(auth.user, "avatar");

  if (avatar !== "") {
    return (
      <Avatar
        size={size}
        src={
          image
          // ||
          // avatar + "?updated_at=" + getDefValue(auth.user?.avatar, "updated_at")
        }
      />
    );
  } else if (updateAdmin.image) {
    return <Avatar size={size} src={updateAdmin.image} />;
  } else {
    return <Avatar size={size} icon={<UserOutlined />} />;
  }
};

export default UserAvatar;

