import API from "@configs/api";
import { AppDispatch } from "@redux/store";
import { logout, setUser } from "@redux/slice/AuthSlice";

export const GetCurrentUserService = {
  run: (dispatch: AppDispatch) => {
    API.getDetailUser()
      .then((response: any) => {
        dispatch(setUser({ user: response.data.data }));
      })
      .catch(() => {
        dispatch(logout());
      });
  },
};
