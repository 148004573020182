import React, { Suspense } from "react";
import { BrowserRouter, Routes as RouterRoutes, Route } from "react-router-dom";
import PrivateRoute from "@routes/PrivateRouter";
import Login from "@pages/login";
import Page404 from "@pages/page404";
import { PERMISSION_DEFAULT, RESOURCE_DEFAULT, ROUTE_PATH } from "@ts/enums";
import { useSelector } from "react-redux";
import { RootState } from "@redux/store";
import {
  // CREATE_COMPANY_PATH,
  // CREATE_PERSON_PATH,
  // CREATE_ROLE_PATH,
  DEFAULT_PATH,
  // VIEW_COMPANY_PATH,
  // VIEW_PERSON_PATH,
  // VIEW_ROLE_PATH,
  VIEW_USER_PATH,
} from "@routes/Routers/constant";

import Dashboard from "@pages/dashboard";
import CustomSkeletonPage from "@components/Skeleton/skeleton-page";

const HomePage = React.lazy(() => import("../../pages/home"));
const PrivacyPolicyPage = React.lazy(
  () => import("../../pages/clause/privacy-policy")
);
const QuestionPage = React.lazy(() => import("../../pages/clause/questions"));
const RegulationOfUsePage = React.lazy(
  () => import("../../pages/clause/regulation-of-use")
);
const ShareApplicationPage = React.lazy(
  () => import("../../pages/clause/share-application")
);
const TermOfServicePage = React.lazy(
  () => import("../../pages/clause/terms-of-service")
);
const HelpGuidePage = React.lazy(() => import("../../pages/clause/guide"));

const Routes = () => {
  const auth = useSelector((state: RootState) => state.auth);
  const roles = auth.roles;
  const resource = roles.resources;
  const routes_not_auth = [
    { path: "/", element: <HomePage /> },
    { path: "/quy-dinh-su-dung", element: <RegulationOfUsePage /> },
    { path: "/chinh-sach-bao-mat", element: <PrivacyPolicyPage /> },
    { path: "/dieu-khoan-dich-vu", element: <TermOfServicePage /> },
    { path: "/chia-se-ung-dung", element: <ShareApplicationPage /> },
    { path: "/mot-so-cau-hoi-thuong-gap", element: <QuestionPage /> },
    { path: "/huong-dan-su-dung", element: <HelpGuidePage /> },
  ];
  let PrivatePages = DEFAULT_PATH;
  if (resource) {
    resource.forEach(
      (resource: {
        resourceId: number;
        resourceName: string;
        resourceKey: string;
        permissions: any;
      }) => {
        if (resource.resourceKey === RESOURCE_DEFAULT.USER_CMS_MANAGEMENT.key) {
          // resource.permissions.forEach(
          //   (permission: {
          //     permissionId: number;
          //     permissionName: string;
          //     permissionKey: string;
          //   }) => {
          //     if (permission.permissionKey === PERMISSION_DEFAULT.CREATE.key) {
          //       PrivatePages = PrivatePages.concat(CREATE_USER_PATH);
          //     } else if (
          //       permission.permissionKey === PERMISSION_DEFAULT.VIEW.key
          //     ) {
          //       PrivatePages = PrivatePages.concat(VIEW_USER_PATH);
          //     }
          //   }
          // );
        } else if (
          resource.resourceKey === RESOURCE_DEFAULT.USER_LP_MANAGEMENT.key
        ) {
          // resource.permissions.forEach(
          //   (permission: {
          //     permissionId: number;
          //     permissionName: string;
          //     permissionKey: string;
          //   }) => {
          //     if (permission.permissionKey === PERMISSION_DEFAULT.VIEW.key) {
          //       PrivatePages = PrivatePages.concat(VIEW_USER_LP_PATH);
          //     }
          //   }
          // );
        } else if (resource.resourceKey === RESOURCE_DEFAULT.CMS_ROLE.key) {
          // resource.permissions.forEach(
          //   (permission: {
          //     permissionId: number;
          //     permissionName: string;
          //     permissionKey: string;
          //   }) => {
          //     if (permission.permissionKey === PERMISSION_DEFAULT.CREATE.key) {
          //       PrivatePages = PrivatePages.concat(CREATE_ROLE_PATH);
          //     } else if (
          //       permission.permissionKey === PERMISSION_DEFAULT.VIEW.key
          //     ) {
          //       PrivatePages = PrivatePages.concat(VIEW_ROLE_PATH);
          //     }
          //   }
          // );
        } else if (resource.resourceKey === RESOURCE_DEFAULT.LP_ROLE.key) {
          // resource.permissions.forEach(
          //   (permission: {
          //     permissionId: number;
          //     permissionName: string;
          //     permissionKey: string;
          //   }) => {
          //     if (permission.permissionKey === PERMISSION_DEFAULT.CREATE.key) {
          //       PrivatePages = PrivatePages.concat(CREATE_ROLE_LP_PATH);
          //     } else if (
          //       permission.permissionKey === PERMISSION_DEFAULT.VIEW.key
          //     ) {
          //       PrivatePages = PrivatePages.concat(VIEW_ROLE_LP_PATH);
          //     }
          //   }
          // );
        } else if (resource.resourceKey === RESOURCE_DEFAULT.COMPANY.key) {
          // resource.permissions.forEach(
          //   (permission: {
          //     permissionId: number;
          //     permissionName: string;
          //     permissionKey: string;
          //   }) => {
          //     if (permission.permissionKey === PERMISSION_DEFAULT.CREATE.key) {
          //       PrivatePages = PrivatePages.concat(CREATE_COMPANY_PATH);
          //     } else if (
          //       permission.permissionKey === PERMISSION_DEFAULT.VIEW.key
          //     ) {
          //       PrivatePages = PrivatePages.concat(VIEW_COMPANY_PATH);
          //     }
          //   }
          // );
        } else if (resource.resourceKey === RESOURCE_DEFAULT.INDUSTRY.key) {
          // resource.permissions.forEach(
          //   (permission: {
          //     permissionId: number;
          //     permissionName: string;
          //     permissionKey: string;
          //   }) => {
          //     if (permission.permissionKey === PERMISSION_DEFAULT.CREATE.key) {
          //       PrivatePages = PrivatePages.concat(CREATE_INDUSTRY_PATH);
          //     } else if (
          //       permission.permissionKey === PERMISSION_DEFAULT.VIEW.key
          //     ) {
          //       PrivatePages = PrivatePages.concat(VIEW_INDUSTRY_PATH);
          //     }
          //   }
          // );
        } else if (resource.resourceKey === RESOURCE_DEFAULT.COUNTRY.key) {
          // resource.permissions.forEach(
          //   (permission: {
          //     permissionId: number;
          //     permissionName: string;
          //     permissionKey: string;
          //   }) => {
          //     if (permission.permissionKey === PERMISSION_DEFAULT.CREATE.key) {
          //       PrivatePages = PrivatePages.concat(CREATE_COUNTRY_PATH);
          //     } else if (
          //       permission.permissionKey === PERMISSION_DEFAULT.VIEW.key
          //     ) {
          //       PrivatePages = PrivatePages.concat(VIEW_COUNTRY_PATH);
          //     }
          //   }
          // );
        } else if (resource.resourceKey === RESOURCE_DEFAULT.REGION.key) {
          // resource.permissions.forEach(
          //   (permission: {
          //     permissionId: number;
          //     permissionName: string;
          //     permissionKey: string;
          //   }) => {
          //     if (permission.permissionKey === PERMISSION_DEFAULT.CREATE.key) {
          //       PrivatePages = PrivatePages.concat(CREATE_REGION_PATH);
          //     } else if (
          //       permission.permissionKey === PERMISSION_DEFAULT.VIEW.key
          //     ) {
          //       PrivatePages = PrivatePages.concat(VIEW_REGION_PATH);
          //     }
          //   }
          // );
        } else if (resource.resourceKey === RESOURCE_DEFAULT.PERSON.key) {
          // resource.permissions.forEach(
          //   (permission: {
          //     permissionId: number;
          //     permissionName: string;
          //     permissionKey: string;
          //   }) => {
          //     if (permission.permissionKey === PERMISSION_DEFAULT.CREATE.key) {
          //       PrivatePages = PrivatePages.concat(CREATE_PERSON_PATH);
          //     } else if (
          //       permission.permissionKey === PERMISSION_DEFAULT.VIEW.key
          //     ) {
          //       PrivatePages = PrivatePages.concat(VIEW_PERSON_PATH);
          //     }
          //   }
          // );
        } else if (resource.resourceKey === RESOURCE_DEFAULT.ACQUISITION.key) {
          // resource.permissions.forEach(
          //   (permission: {
          //     permissionId: number;
          //     permissionName: string;
          //     permissionKey: string;
          //   }) => {
          //     if (permission.permissionKey === PERMISSION_DEFAULT.CREATE.key) {
          //       PrivatePages = PrivatePages.concat(CREATE_TRANSACTION_PATH);
          //     } else if (
          //       permission.permissionKey === PERMISSION_DEFAULT.VIEW.key
          //     ) {
          //       PrivatePages = PrivatePages.concat(VIEW_TRANSACTION_PATH);
          //     }
          //   }
          // );
        } else if (resource.resourceKey === RESOURCE_DEFAULT.NEWS.key) {
          // resource.permissions.forEach(
          //   (permission: {
          //     permissionId: number;
          //     permissionName: string;
          //     permissionKey: string;
          //   }) => {
          //     if (permission.permissionKey === PERMISSION_DEFAULT.CREATE.key) {
          //       PrivatePages = PrivatePages.concat(CREATE_NEWS_PATH);
          //     } else if (
          //       permission.permissionKey === PERMISSION_DEFAULT.VIEW.key
          //     ) {
          //       PrivatePages = PrivatePages.concat(VIEW_NEWS_PATH);
          //     }
          //   }
          // );
        } else if (resource.resourceKey === RESOURCE_DEFAULT.INSIGHT.key) {
          // resource.permissions.forEach(
          //   (permission: {
          //     permissionId: number;
          //     permissionName: string;
          //     permissionKey: string;
          //   }) => {
          //     if (permission.permissionKey === PERMISSION_DEFAULT.CREATE.key) {
          //       PrivatePages = PrivatePages.concat(CREATE_INSIGHT_PATH);
          //     } else if (
          //       permission.permissionKey === PERMISSION_DEFAULT.VIEW.key
          //     ) {
          //       PrivatePages = PrivatePages.concat(VIEW_INSIGHT_PATH);
          //     }
          //   }
          // );
        } else if (resource.resourceKey === RESOURCE_DEFAULT.INVESTOR.key) {
          // resource.permissions.forEach(
          //   (permission: {
          //     permissionId: number;
          //     permissionName: string;
          //     permissionKey: string;
          //   }) => {
          //     if (permission.permissionKey === PERMISSION_DEFAULT.CREATE.key) {
          //       PrivatePages = PrivatePages.concat(CREATE_INVESTOR_PATH);
          //     } else if (
          //       permission.permissionKey === PERMISSION_DEFAULT.VIEW.key
          //     ) {
          //       PrivatePages = PrivatePages.concat(VIEW_INVESTOR_PATH);
          //     }
          //   }
          // );
        } else if (resource.resourceKey === RESOURCE_DEFAULT.ANALYSIS.key) {
          // resource.permissions.forEach(
          //   (permission: {
          //     permissionId: number;
          //     permissionName: string;
          //     permissionKey: string;
          //   }) => {
          //     if (permission.permissionKey === PERMISSION_DEFAULT.CREATE.key) {
          //       PrivatePages = PrivatePages.concat(CREATE_ANALYSIS_PATH);
          //     } else if (
          //       permission.permissionKey === PERMISSION_DEFAULT.VIEW.key
          //     ) {
          //       PrivatePages = PrivatePages.concat(VIEW_ANALYSIS_PATH);
          //     }
          //   }
          // );
        } else if (resource.resourceKey === RESOURCE_DEFAULT.VIDEO.key) {
          // resource.permissions.forEach(
          //   (permission: {
          //     permissionId: number;
          //     permissionName: string;
          //     permissionKey: string;
          //   }) => {
          //     if (permission.permissionKey === PERMISSION_DEFAULT.CREATE.key) {
          //       PrivatePages = PrivatePages.concat(
          //         CREATE_VIDEO_DISTRIBUTION_PATH
          //       );
          //     } else if (
          //       permission.permissionKey === PERMISSION_DEFAULT.VIEW.key
          //     ) {
          //       PrivatePages = PrivatePages.concat(
          //         VIEW_VIDEO_DISTRIBUTION_PATH
          //       );
          //     }
          //   }
          // );
        } else if (resource.resourceKey === RESOURCE_DEFAULT.PAYMENT.key) {
          // resource.permissions.forEach(
          //   (permission: {
          //     permissionId: number;
          //     permissionName: string;
          //     permissionKey: string;
          //   }) => {
          //     if (permission.permissionKey === PERMISSION_DEFAULT.VIEW.key) {
          //       PrivatePages = PrivatePages.concat(VIEW_PAYMENT_PATH);
          //     }
          //   }
          // );
        }
      }
    );
  }

  return (
    <BrowserRouter>
      <RouterRoutes>
        {/* Các Route không yêu cầu xác thực */}
        {routes_not_auth.map((route) => (
          <Route key={route.path} path={route.path} element={route.element} />
        ))}

        {/* Các route riêng có bảo vệ */}
        {PrivatePages.map((i) => (
          <Route
            path={i.path}
            key={i.path}
            element={<PrivateRoute>{i.element}</PrivateRoute>}
          />
        ))}

        {/* Route cho Login */}
        <Route path={ROUTE_PATH.login} element={<Login />} />

        {/* Route cho trang lỗi */}
        <Route path="*" element={<Page404 />} />
      </RouterRoutes>
    </BrowserRouter>
  );
};

export default Routes;
