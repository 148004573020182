import { Button, Form, Input, Spin } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AdminDetailServices } from "@pages/Users/UserDetail/Service";
// import { UserDetailDataType } from "@ts/interfaces";
import { useDocumentTitle } from "@hooks/useDocumentTitle";
import { RootState } from "@redux/store";
import NotificationCustom from "@components/NotificationCustom";
import BreadcrumbCustom from "@components/BreadcrumbCustom";
import UploadImageComponent from "@components/UploadImageComponent";
import { UserDetailDataType } from "@ts/interfaces";

const UserDetail = () => {
  useDocumentTitle("User Details");
  const dispatch = useDispatch();
  const detailAdmin: UserDetailDataType = useSelector(
    (state: RootState) => state.singleAdmin.data,
  );
  const [notify, setNotify] = useState({
    isOpen: false,
    type: "",
    content: "",
  });
  const [isChange, setIsChange] = useState(true);
  const { loading, image } = useSelector(
    (state: RootState) => state.updateAdmin,
  );
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    AdminDetailServices.getDetailUser(dispatch, onLoading);
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      fullName: detailAdmin.tenNguoiDung,
    });
  }, [detailAdmin, form]);

  const handleUpdate = (values: any) => {
    const updateInfo = {
      fullName: values.tenNguoiDung ? values.tenNguoiDung : "",
    };
    AdminDetailServices.updateAdmin(dispatch, updateInfo, onSuccess);
    setIsChange(true);
  };

  const handleUpdateAvatar = (params: any) => {
    AdminDetailServices.uploadAva(dispatch, params, onSuccess);
  };

  const hideNotify = () => {
    setTimeout(() => {
      setNotify({
        isOpen: false,
        type: "",
        content: "",
      });
    }, 2000);
  };

  const onSuccess = () => {
    setNotify({
      isOpen: true,
      type: "success",
      content: "You have update successfully!",
    });
    hideNotify();
  };

  const onLoading = () => {
    setIsLoading(false);
  };

  const handleFullNameChange = (value: any) => {
    setIsChange(value === detailAdmin.tenNguoiDung);
  };

  const items: any = [
    { label: "Home", key: "Home", path: "/" },
    { label: "User Detail", key: "userDetail", path: null },
  ];

  const VALIDATE_MESSAGE = {
    require: "Please input your full name!",
    length: "Name must be between 2 and 30 characters",
  };

  return (
    <>
      <div
        className="list-user-cms-header"
        style={{ backgroundColor: "#FFFFFF", height: "150px", padding: "2%" }}
      >
        <BreadcrumbCustom path={items} />
        <h1 style={{ marginTop: "15px" }}>User Detail</h1>
      </div>
      <div className="search-person" style={{ padding: "2%" }}>
        {notify?.isOpen && (
          <NotificationCustom type={notify.type} content={notify.content} />
        )}
        {isLoading ? (
          <div
            style={{
              margin: "20px 0",
              marginBottom: "20px",
              padding: "30px 50px",
              textAlign: "center",
              borderRadius: "4px",
            }}
          >
            <Spin size="large" tip="Loading..." />
          </div>
        ) : (
          <Form
            form={form}
            onFinish={handleUpdate}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 14 }}
            layout="horizontal"
            disabled={false}
          >
            <Form.Item
              label="Full Name"
              name="tenNguoiDung"
              rules={[
                { required: true, message: VALIDATE_MESSAGE.require },
                {
                  type: "string",
                  min: 2,
                  max: 30,
                  message: VALIDATE_MESSAGE.length,
                },
              ]}
            >
              <Input
                placeholder="tenNguoiDung"
                onChange={(event: any) => {
                  handleFullNameChange(event.target.value);
                }}
              />
            </Form.Item>
            <Form.Item
              label="Email"
              rules={[{ required: true }, { type: "string", min: 6 }]}
            >
              <Input placeholder="Email" value={detailAdmin.email} readOnly />
            </Form.Item>
            {/* <Form.Item
              label="Roles"
              rules={[{ required: true }, { type: "string", min: 6 }]}
            >
              <Input
                placeholder="Role"
                value={detailAdmin.roles[0].roleName}
                readOnly
              />
            </Form.Item>
            <Form.Item label="Upload" name="avatar">
              <UploadImageComponent
                image={image || detailAdmin.avatar}
                uploadFile={handleUpdateAvatar}
              />
            </Form.Item> */}
            <Form.Item wrapperCol={{ offset: 4, span: 10 }}>
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                disabled={isChange}
              >
                Update
              </Button>
            </Form.Item>
          </Form>
        )}
      </div>
    </>
  );
};

export default UserDetail;
