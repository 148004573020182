import { ROUTE_PATH } from "@ts/enums";
import Dashboard from "@pages/dashboard";
import ChangePassword from "@pages/Users/ChangePassword";
import UserDetail from "@pages/Users/UserDetail";
import ListUsers from "@pages/Users/ListUsers";
import HealthCompany from "@pages/health/company";
import HealthHospital from "@pages/health/hospital";

export const DEFAULT_PATH = [
  {
    path: ROUTE_PATH.dashboard,
    element: <Dashboard />,
  },
  {
    path: ROUTE_PATH.changePassword,
    element: <ChangePassword />,
  },
  {
    path: ROUTE_PATH.profile,
    element: <UserDetail />,
  },
  {
    path: ROUTE_PATH.healthCompany,
    element: <HealthCompany />,
  },
  {
    path: ROUTE_PATH.healthHospital,
    element: <HealthHospital />,
  },
];

// export const CREATE_USER_PATH = [
//   {
//     path: ROUTE_PATH.createNewUser,
//     element: <CreateUser />,
//   },
// ];

export const VIEW_USER_PATH = [
  {
    path: ROUTE_PATH.listUsers,
    element: <ListUsers />,
  },
  {
    path: ROUTE_PATH.userId,
    element: <UserDetail />,
  },
];

// export const CREATE_ROLE_PATH = [
//   {
//     path: ROUTE_PATH.createRole,
//     element: <CreateRoles />,
//   },
// ];

// export const CREATE_ROLE_LP_PATH = [
//   {
//     path: ROUTE_PATH.createRoleLP,
//     element: <CreateRolesLP />,
//   },
// ];

// export const VIEW_ROLE_PATH = [
//   {
//     path: ROUTE_PATH.listRoles,
//     element: <Roles />,
//   },
//   {
//     path: ROUTE_PATH.roleId,
//     element: <EditRole />,
//   },
// ];

// export const VIEW_ROLE_LP_PATH = [
//   {
//     path: ROUTE_PATH.listRolesLandingPage,
//     element: <RolesLandingPage />,
//   },
//   {
//     path: ROUTE_PATH.roleIdLP,
//     element: <EditRoleLP />,
//   },
// ];

// export const CREATE_COMPANY_PATH = [
//   {
//     path: ROUTE_PATH.companyCreateNew,
//     element: <CreateCompany />,
//   },
// ];

// export const VIEW_COMPANY_PATH = [
//   {
//     path: ROUTE_PATH.companyId,
//     element: <EditCompany />,
//   },
//   {
//     path: ROUTE_PATH.listCompany,
//     element: <ListCompany />,
//   },
// ];
// export const CREATE_INDUSTRY_PATH = [
//   {
//     path: ROUTE_PATH.industryCreateNew,
//     element: <CreateIndustry />,
//   },
// ];

// export const CREATE_PERSON_PATH = [
//   {
//     path: ROUTE_PATH.personCreateNew,
//     element: <CreatePerson />,
//   },
//   {
//     element: <CreatePerson preview={true} />,
//     path: ROUTE_PATH.previewPerson,
//   },
// ];

// export const VIEW_PERSON_PATH = [
//   {
//     path: ROUTE_PATH.personId,
//     element: <EditPerson />,
//   },
//   {
//     path: ROUTE_PATH.listPerson,
//     element: <ListPerson />,
//   },
// ];
