import { AppDispatch } from "@redux/store";
import {
  singleAdminError,
  singleAdminPending,
  singleAdminSuccess,
} from "@redux/slice/SingleAdminSlice";
import API from "@configs/api";
import { getErr422 } from "@utilities/helper";
import {
  updateAdminError,
  updateAdminPending,
  updateAdminSuccess,
  updateAvatarSuccess,
} from "@redux/slice/UpdateAdminSlice";

export const AdminDetailServices = {
  getDetailUser: (dispatch: AppDispatch, onLoading: () => void) => {
    dispatch(singleAdminPending());
    API.getDetailUser()
      .then((response: any) => {
        onLoading();
        dispatch(singleAdminSuccess(response.data));
      })
      .catch((err: any) => {
        dispatch(singleAdminError(getErr422(err)));
      });
  },
  updateAdmin: (dispatch: AppDispatch, data: any, onSuccess: () => void) => {
    dispatch(updateAdminPending());
    // API.updateAdmin(data)
    //   .then(() => {
    //     dispatch(updateAdminSuccess());
    //     onSuccess();
    //   })
    //   .catch((err: any) => {
    //     dispatch(updateAdminError(getErr422(err)));
    //   });
  },
  uploadAva: (dispatch: AppDispatch, file: any, onSuccess: () => void) => {
    dispatch(updateAdminPending());
    const formData = new FormData();
    const emptyBlob = new Blob([""], { type: "text/plain" });
    if (file.name) {
      formData.append("file", file, file.name);
    } else {
      formData.append("file", emptyBlob, "");
    }
    // API.uploadAvatar(formData)
    //   .then((response: any) => {
    //     dispatch(updateAvatarSuccess(response.data));
    //     onSuccess();
    //   })
    //   .catch((err: any) => {
    //     dispatch(updateAdminError(getErr422(err)));
    //   });
  },
};
