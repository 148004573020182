import { configureStore } from "@reduxjs/toolkit";
import ForgotPasswordSlice from "@redux/slice/ForgotPasswordSlice";
import AuthSlice from "@redux/slice/AuthSlice";
import LoginSlice from "@redux/slice/LoginSlice";
import UpdateAdminSlice from "@redux/slice/UpdateAdminSlice";
import ChangePasswordSlice from "@redux/slice/ChangePasswordSlice";
import SingleAdminSlice from "@redux/slice/SingleAdminSlice";
export const store = configureStore({
  reducer: {
    auth: AuthSlice,
    login: LoginSlice,
    forgotPassword: ForgotPasswordSlice,
    changePassword: ChangePasswordSlice,
    updateAdmin: UpdateAdminSlice,
    singleAdmin: SingleAdminSlice,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
