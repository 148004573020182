import { MESSAGE_FORM_INVESTOR } from "./constant";

export const getDefValue = (dt: any, field: string, def: any = "") => {
  if (dt?.[field]) {
    return dt[field];
  }
  return def;
};

export const getErr422 = (err: any) => {
  if (err?.response?.status === 422) {
    return err.response.data.errors;
  }
  return null;
};

export const getServerErr = (dt: any, field: string, status = false) => {
  let res = undefined;
  if (dt?.[field]) {
    res = dt[field];
    if (Array.isArray(dt[field])) {
      res = dt[field][0];
    }
  }
  if (status) {
    return res ? "error" : undefined;
  }
  return res;
};

export const handleScrollToTop = () => {
  window.scroll({
    top: 0,
    behavior: "smooth",
  });
};

export const checkValidUrl = (value: string) => {
  if (!value.startsWith("http://") && !value.startsWith("https://")) {
    return Promise.reject(new Error(MESSAGE_FORM_INVESTOR.messageUrlHttp));
  }
  return Promise.resolve();
};

export const convertDataWithoutKey = (data: any): any => {
  return data?.map((item: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
    const { key, ...rest } = item;
    return Object.fromEntries(Object.entries(rest));
  });
};

export const checkLinkYoutube = (link: string) => {
  const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+/;
  return youtubeRegex.test(link);
};

export const convertLinkYoutube = (link: string) => {
  let embedLink;
  if (link.includes("/embed/")) {
    embedLink = link;
  } else {
    const videoId = link.split("?v=");
    embedLink = "https://www.youtube.com/embed/" + videoId[1];
  }
  return embedLink;
};

export const handleCheckboxPermission = (
  data: any,
  keys: string,
  permissionKey: string,
) => {
  const elementsWithPermissionKey = [];
  for (const item of data) {
    const permissions = item.permissions;
    const hasPermission = permissions.some(
      (permission: { permissionKey: string }) =>
        permission.permissionKey === permissionKey,
    );

    if (hasPermission) {
      elementsWithPermissionKey.push(item);
    }
  }
  const publishPermissionKey = elementsWithPermissionKey?.map(
    (element: any) => {
      return element?.resourceKey;
    },
  );
  return publishPermissionKey.includes(keys);
};

export const ternaryOperatorHandle = (
  param1: any,
  param2: any,
  param3: any,
) => {
  if (param1) {
    return param2;
  }
  return param3;
};

export const binaryOperatorHandle = (param1: any, param2: any) => {
  if (param1) {
    return param1;
  }
  return param2;
};

export const convertNumberToMonth = (numberOfMonth: number) => {
  if (numberOfMonth === 1) {
    return "Jan";
  } else if (numberOfMonth === 2) {
    return "Feb";
  } else if (numberOfMonth === 3) {
    return "Mar";
  } else if (numberOfMonth === 4) {
    return "Apr";
  } else if (numberOfMonth === 5) {
    return "May";
  } else if (numberOfMonth === 6) {
    return "Jun";
  } else if (numberOfMonth === 7) {
    return "Jul";
  } else if (numberOfMonth === 8) {
    return "Aug";
  } else if (numberOfMonth === 9) {
    return "Sep";
  } else if (numberOfMonth === 10) {
    return "Oct";
  } else if (numberOfMonth === 11) {
    return "Nov";
  } else if (numberOfMonth === 12) {
    return "Dec";
  } else {
    return "";
  }
};
