import { Input } from "antd";
import "./styles.scss";
import { useEffect, useState } from "react";

const SearchComponent = ({
  placeholder,
  onSearch,
  onPressEnter,
  searchKey,
}: any) => {
  const [keySearch, setKeySearch] = useState();
  useEffect(() => {
    setKeySearch(searchKey);
  }, [searchKey]);

  return (
    <Input
      value={keySearch}
      placeholder={placeholder}
      allowClear
      onChange={(e) => onSearch(e.target.value)}
      onPressEnter={onPressEnter}
    />
  );
};

export default SearchComponent;
