import { useState } from "react";
import { Alert, Button, Form, Input, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@redux/store";
import { getServerErr } from "@utilities/helper";
import { PASSWORD_MESSAGE, REGEX } from "@utilities/constant";
import { useDocumentTitle } from "@hooks/useDocumentTitle";
import { ChangePasswordServices } from "@pages/Users/ChangePassword/Service";
import MESSAGES from "@configs/message";
import BreadcrumbCustom from "@components/BreadcrumbCustom";

const ChangePassword = () => {
  const [form] = Form.useForm();
  const [isDuplicatePassword, setIsDuplicatePassword] = useState(false);
  const changePass = useSelector((state: RootState) => state.changePassword);
  const dispatch = useDispatch();
  useDocumentTitle("Change Password");

  const _getServerErr = (field: string, status = false) =>
    getServerErr(changePass.error, field, status);

  const onSuccess = () => {
    message.success("New Password Saved!");
    form.resetFields();
  };

  const items: any = [
    { label: "Home", key: "Home", path: "/" },
    { label: "Change Password", key: "changePassword", path: null },
  ];

  return (
    <>
      <div
        className="list-user-cms-header"
        style={{ backgroundColor: "#FFFFFF", height: "150px", padding: "2%" }}
      >
        <BreadcrumbCustom path={items} />
        <h1 style={{ marginTop: "15px" }}>Change Password</h1>
      </div>
      <div className="search-person" style={{ padding: "2%" }}>
        <Form
          form={form}
          labelCol={{ sm: { span: 24 }, lg: { span: 24 }, xl: { span: 4 } }}
          wrapperCol={{ sm: { span: 24 }, lg: { span: 24 }, xl: { span: 5 } }}
          onFinish={(values) => {
            if (values.newPassword === values.passwordConfirmation) {
              delete values.passwordConfirmation;
              ChangePasswordServices.run(dispatch, values, onSuccess);
            } else {
              setIsDuplicatePassword(true);
            }
          }}
        >
          <Form.Item
            label="Old Password"
            name="oldPassword"
            validateStatus={_getServerErr("oldPassword", true)}
            help={_getServerErr("oldPassword")}
            rules={[{ required: true, message: MESSAGES.required }]}
          >
            <Input.Password
              placeholder="Old Password"
              onChange={() => {
                setIsDuplicatePassword(false);
              }}
            />
          </Form.Item>
          <Form.Item
            label="New Password"
            name="newPassword"
            validateStatus={_getServerErr("newPassword", true)}
            help={_getServerErr("newPassword")}
            rules={[
              { required: true, message: MESSAGES.required },
              {
                min: 8,
                pattern: REGEX.PASSWORD,
                message: PASSWORD_MESSAGE,
              },
            ]}
          >
            <Input.Password
              placeholder="New Password"
              onChange={() => {
                setIsDuplicatePassword(false);
              }}
            />
          </Form.Item>
          <Form.Item
            label="Password Confirmation"
            name="passwordConfirmation"
            help={_getServerErr("passwordConfirmation")}
            rules={[
              { required: true, message: MESSAGES.required },
              {
                min: 8,
                pattern: REGEX.PASSWORD,
                message: PASSWORD_MESSAGE,
              },
            ]}
          >
            <Input.Password
              placeholder="Password Confirmation"
              onChange={() => {
                setIsDuplicatePassword(false);
              }}
            />
          </Form.Item>
          {isDuplicatePassword ? (
            <div style={{ paddingBottom: "15px" }}>
              <Alert
                message={MESSAGES.passwordNotMatch}
                type="error"
                showIcon
              />
            </div>
          ) : (
            <div />
          )}
          <Form.Item wrapperCol={{ xl: { offset: 4 } }}>
            <Button
              type="primary"
              htmlType="submit"
              loading={changePass.loading}
            >
              Change Password
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default ChangePassword;
