import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { RootState } from "@redux/store";
import AppLayout from "@components/AppLayout";

interface Props {
  children: React.ReactNode;
  path?: string;
  exact?: boolean;
}

const PrivateRoute = ({ children, ...rest }: Props) => {
  const auth = useSelector((state: RootState) => state.auth);
  const isLogin = auth.token && auth.user;

  return isLogin ? (
    <AppLayout>{children}</AppLayout>
  ) : (
    <Navigate to="/cms/login" replace />
  );
};

export default PrivateRoute;
