import React, { useEffect, useState } from "react";
import {
  DatePicker,
  Empty,
  notification,
  Row,
  Space,
  Table,
  Tooltip,
  Col,
  Tag,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import "./styles.scss";
import moment from "moment";
import API from "@configs/api";
import {
  DATE_FORMAT,
  FIRST_PAGE,
  FIRST_SIZE,
  NOT_HAVE_PERMISSION,
  NO_DATA,
  PUBLISH_VALUE,
  UNPUBLISH_VALUE,
  FORMAT_DATE,
  PUBLISH_LABEL,
  UNPUBLISH_LABEL,
} from "@utilities/constant";
import { useDocumentTitle } from "@hooks/useDocumentTitle";
// import { InsightDataType } from "@ts/interfaces";
import SearchComponent from "@components/SearchComponent";
import PaginationCustom from "@components/PaginationCustom";
import { PERMISSION_DEFAULT, RESOURCE_DEFAULT, SERVICE_TYPE } from "@ts/enums";
import { useSelector } from "react-redux";
import { RootState } from "@redux/store";
import BreadcrumbCustom from "@components/BreadcrumbCustom";

const HealthHospital = () => {
  useDocumentTitle("Health Hospital External");
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [dataHealthCheckHospital, setDataHealthCheckHospital] = useState([]);
  const [dataDatePick, setDataDatePick] = useState<any>("");
  const [searchValue, setSearchValue] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [totalPagination, setTotalPagination] = useState<number>(0);
  const [refresh, setRefresh] = useState<boolean>(true);
  const [hasPermissionPublish, setHasPermissionPublish] = useState(false);
  const auth = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    setLoading(true);
    API.getListServiceCheck(searchValue, SERVICE_TYPE.EXTERNAL, page - 1, size)
      .then((response: any) => {
        setDataHealthCheckHospital(response?.data.data);
        setTotalPagination(response?.data?.total || 10);
        setLoading(false);
      })
      .catch((error: any) => {
        notification["error"]({
          message:
            error.response?.data?.message ||
            error.response?.data?.error?.message ||
            "",
        });
        setLoading(false);
      });
    const currentResource = auth?.roles?.resources?.filter(
      (item: any) => RESOURCE_DEFAULT.INSIGHT.key === item.resourceKey,
    );
    if (currentResource?.length) {
      const persmisionForCreateInsight = currentResource[0].permissions.filter(
        (item: any) => PERMISSION_DEFAULT.CREATE.key === item.permissionKey,
      );
      const permissionForPublishInsight =
        currentResource[0]?.permissions.filter(
          (item: any) => PERMISSION_DEFAULT.PUBLISH.key === item.permissionKey,
        );
      if (permissionForPublishInsight?.length) {
        setHasPermissionPublish(true);
      }
    }
    return () => {
      setDataHealthCheckHospital([]);
      setSelectedRowKeys([]);
    };
  }, [refresh]);

  const columns: ColumnsType<any> = [
    {
      title: "Tên dịch vụ",
      dataIndex: "serviceName",
      key: "serviceName",
      render: (serviceName) => (
        <span title={serviceName || ""}>{serviceName ?? "-"}</span>
      ),
    },
    {
      title: "Trạng thái hoạt động",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        if (!status) return "-";
        const statusMap: Record<string, any> = {
          ACTIVE: { color: "green", text: "Đang hoạt động" },
          CHECKING: { color: "gold", text: "Đang kiểm tra" },
          INACTIVE: { color: "red", text: "Không hoạt động" },
        };

        const { color, text } = statusMap[status] || {};
        return (
          <Tag color={color} style={{ fontWeight: "bold" }}>
            {text}
          </Tag>
        );
      },
    },
    {
      title: "Địa chỉ IP",
      dataIndex: "urlHealthCheck",
      key: "urlHealthCheck",
      render: (urlHealthCheck) => {
        const getIp = (url: string) => {
          try {
            const match = url.match(/^https?:\/\/([\d.]+):(\d+)/);
            return match ? match[1] : "-"; // Trả về IP nếu khớp
          } catch {
            return "-";
          }
        };

        return (
          <span title={urlHealthCheck || ""}>{getIp(urlHealthCheck)}</span>
        );
      },
    },
    {
      title: "Port",
      dataIndex: "urlHealthCheck",
      key: "urlHealthCheck-port",
      render: (urlHealthCheck) => {
        const getPort = (url: string) => {
          try {
            const match = url.match(/^https?:\/\/([\d.]+):(\d+)/);
            return match ? match[2] : "-"; // Trả về Port nếu khớp
          } catch {
            return "-";
          }
        };

        return (
          <span title={urlHealthCheck || ""}>{getPort(urlHealthCheck)}</span>
        );
      },
    },
    {
      title: "Mô tả",
      dataIndex: "description",
      key: "description",
      render: (description) => (description ? <div>{description}</div> : "-"),
    },
    {
      title: "Thời gian kiểm tra",
      dataIndex: "lastModifiedAt",
      key: "lastModifiedAt",
      render: (lastModifiedAt) => (
        <>
          {lastModifiedAt
            ? moment(lastModifiedAt)
                .format(FORMAT_DATE)
                .split(",")
                .map((part, index) => (
                  <React.Fragment key={part}>
                    {part}
                    {index !==
                      moment(lastModifiedAt).format(FORMAT_DATE).split(",")
                        .length -
                        1 && (
                      <>
                        ,&ensp;
                        <br />
                      </>
                    )}
                  </React.Fragment>
                ))
            : "-"}
        </>
      ),
    },
  ];

  const handleTableChange = (page: any, size: any) => {
    setPage(page);
    setSize(size);
    setRefresh(!refresh);
  };

  const refreshInsight = () => {
    setPage(FIRST_PAGE);
    setSize(FIRST_SIZE);
    setRefresh(!refresh);
  };

  const itemsBreadcrumb: any = [
    {
      key: "Dashboard",
      label: "Dashboard",
      path: "/cms/dashboard",
    },
    {
      key: "HealthHospital",
      label: "Health Hospital",
      path: null,
    },
  ];
  const items: any = [
    {
      value: NO_DATA,
      label: "All Status",
    },
    {
      value: PUBLISH_VALUE,
      label: PUBLISH_LABEL,
    },
    {
      value: UNPUBLISH_VALUE,
      label: UNPUBLISH_LABEL,
    },
  ];

  const handleSearchByDate = (value: any) => {
    setDataDatePick(value ? moment(value).startOf("day").toISOString() : "");
    setRefresh(!refresh);
  };

  const handleSearch = (value: string) => {
    setSearchValue(value.trim());
    setPage(FIRST_PAGE);
    setSize(FIRST_SIZE);
    setRefresh(!refresh);
  };

  return (
    <>
      <div className="list-insight-header">
        <BreadcrumbCustom path={itemsBreadcrumb} />
        <h1 style={{ marginTop: "15px" }}>Dịch vụ của His</h1>
      </div>
      <div className="search-insight" style={{ padding: "2%" }}>
        <Row gutter={[16, 16]} align="middle">
          <Col span={12}>
            <Space size={[2, 16]} style={{ width: "100%" }}>
              <div style={{ fontSize: "14px", width: "40px" }}>Title:</div>
              <div style={{ width: "100%" }}>
                <SearchComponent
                  placeholder="Please enter"
                  onPressEnter={() => refreshInsight()}
                  searchKey={searchValue}
                  onSearch={handleSearch}
                />
              </div>
            </Space>
          </Col>
        </Row>
      </div>
      <div className="list-insight-container" style={{ padding: "2%" }}>
        <Table
          columns={columns}
          dataSource={dataHealthCheckHospital}
          rowKey="id"
          pagination={false}
          loading={loading}
          scroll={{ x: 1200 }}
          locale={{
            emptyText: loading ? (
              <div style={{ height: "100px", background: "#fff" }}></div>
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            ),
          }}
        />
        {!loading && (
          <PaginationCustom
            total={totalPagination}
            handleTableChange={handleTableChange}
            current={page}
            size={size}
          />
        )}
      </div>
    </>
  );
};

export default HealthHospital;
